export default {
  products: 'Mis productos',
  dashboard: 'Dashboard',
  posts: 'Publicaciones',
  settings: 'Configuración',
  widget: 'widget',
  categories: 'Categorías',
  invalidSize: 'Tamaño del logo inválido',
  width: 'Ancho entre',
  height: 'Altura entre',
  preview: 'Vista previa',
  releaseNotes: 'Ver notas de publicación',
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './DataBubbleStyle';

const DataBubble = ({
  title, value, icon, spanColor,
}) => {
  const classes = useStyles();
  return (
    <Paper
      style={{ borderLeft: `8px solid ${spanColor}` }}
      elevation={3}
      className={classes.paper}
    >
      <Typography style={{ color: '#738FA4' }} variant="subtitle2">{title}</Typography>
      <div className={classes.bubbleData}>
        {icon}
        {icon && (
        <span style={{ width: '10px' }} />
        )}
        <Typography variant="h2">{value}</Typography>
      </div>

    </Paper>
  );
};

export default DataBubble;

DataBubble.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  spanColor: PropTypes.string.isRequired,
};

import React, { useState, useEffect, useContext } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Typography,
  Button,
  Drawer,
  CircularProgress,
} from '@material-ui/core';
import Eye from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from './DashboardStyle';
import DataBubble from '../DataBubble';
import ProductCard from '../ProductCard';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import DoubleAxisChart from '../DoubleAxisChart';
import TopPosts from '../TopPosts';

// Utils
import numToMonth from '../../../utils/numToMonth';

// Context
import { actions } from '../../../context/Reducer';

const shortenDate = (date) => {
  const dateStr = date.toString();
  const shortDate = dateStr.slice(0, 15);
  return shortDate;
};

const combineArrays = (array1, array2) => {
  const finalArray = array1;
  array1.forEach((value, index) => {
    finalArray[index] = { ...value, views: array2[index].views };
  });

  return finalArray;
};

const timeMachine = (currDate, pastDays) => {
  const today = new Date(currDate);
  const past = new Date();
  past.setDate(today.getDate() - pastDays);
  return past;
};

const mapTopPosts = (data) => {
  const userLang = navigator.language || navigator.userLanguage;
  const array = data.map((post) => {
    const creationTmp = new Date(post.attributes.created_at);
    const localeDate = creationTmp.toLocaleString(userLang, { dateStyle: 'full' });
    return ({
      id: post.id,
      title: post.attributes.title,
      body: post.attributes.blurb,
      views: post.attributes.view_counter,
      creation: localeDate,
      author: post.attributes.created_by_name,
    });
  });
  return array;
};

const Dashboard = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { product } = API;
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const currentProduct = state.product;
  const { t } = useTranslation(['dashboard', 'common', 'months']);
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [topPosts, setTopPosts] = useState({
    evenPosts: [],
    unevenPosts: [],
  });
  const [productData, setProductData] = useState({
    name: '',
    website: '',
    description: '',
  });
  const [chartData, setChartData] = useState([]);
  const [dataBubbles, setDataBubbles] = useState([]);

  const fetchProduct = async () => {
    try {
      const { data } = await product.showProduct(currentProduct.id, state.user.token);
      const dataReady = {
        name: data.data.attributes.name,
        website: data.data.attributes.website,
        description: data.data.attributes.description,
        uri: data.data.attributes.product_uri,
      };
      setProductData(dataReady);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const editProduct = async () => {
    try {
      const { data } = await product.updateProduct(currentProduct.id, {
        name: productData.name,
        website: productData.website,
        description: productData.description,
      }, state.user.token);
      dispatch({ type: actions.SET_PRODUCT, payload: data.data });
      setOpen(false);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 422) {
        enqueueSnackbar(
          t('common:snackbarMessages:formError'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const handleOpen = () => {
    fetchProduct()
      .then((r) => {
        setOpen(true);
      });
  };

  const changeDate = (dateParam, newDate) => {
    const shortDate = shortenDate(newDate);
    setDate({
      ...date,
      [dateParam]: shortDate,
    });
  };

  const mapChartData = (data, startDate, endDate, label) => {
    const currentDate = new Date(startDate);
    currentDate.setDate(1);
    const lastDate = new Date(endDate);
    const array = [];

    while (currentDate <= lastDate) {
      const currMonth = currentDate.getMonth() + 1;
      const currYear = currentDate.getFullYear();

      const result = Object.entries(data).filter(([date, value]) => {
        const month = parseInt(date.slice(0, 2), 10);
        const year = parseInt(date.slice(3), 10);
        return currMonth === month && currYear === year;
      });

      if (result.length) {
        array.push({ month: numToMonth(currMonth, t), [label]: result[0][1] });
      } else {
        array.push({ month: numToMonth(currMonth, t), [label]: 0 });
      }
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return array;
  };

  const fetchData = async (startDate, endDate) => {
    const bubblesTmp = [
      {
        color: '#87BBE3', title: '', value: 0, icon: <Eye className={classes.icon} />,
      },
      {
        color: '#FEBE58', title: '', value: 0, icon: null,
      },
    ];
    try {
      const { data } = await product.showDashboard({
        start_date: startDate,
        end_date: endDate,
        product_id: currentProduct.id,
      }, state.user.token);

      const postsData = mapChartData(data.post_per_month, startDate, endDate, 'posts');
      const viewsData = mapChartData(data.views_per_month, startDate, endDate, 'views');
      const chart = combineArrays(postsData, viewsData);
      setChartData(chart);

      bubblesTmp[0].value = data.views;
      bubblesTmp[0].title = t('dashboard:dataBubbles.views');
      bubblesTmp[1].value = data.post;
      bubblesTmp[1].title = t('dashboard:dataBubbles.published');
      setDataBubbles(bubblesTmp);

      const mappedPosts = mapTopPosts(data.top.data);
      setTopPosts([...mappedPosts]);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const today = new Date();
    const past = timeMachine(today, 90);
    const todayShort = shortenDate(today);
    const pastShort = shortenDate(past);

    fetchData(pastShort, todayShort);
    setDate({
      startDate: pastShort,
      endDate: todayShort,
    });
    // eslint-disable-next-line
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return (
      <div className={classes.loadingRoot}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div style={{ height: '100%', backgroundColor: 'white' }}>
      <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
        <ProductCard
          edit
          steps={[]}
          activeStep={0}
          onClose={onClose}
          productData={productData}
          setProductData={setProductData}
          auxFunction={editProduct}
          buttonTxt={t('common:actions.save')}
        />
      </Drawer>
      <div className={classes.topContainer}>
        <Typography
          variant="h2"
        >
          Dashboard
        </Typography>
        <Button
          className={classes.editButton}
          startIcon={<EditIcon style={{ fontSize: '16px' }} />}
          variant="contained"
          color="primary"
          onClick={handleOpen}
        >
          {t('dashboard:editButton')}
        </Button>
      </div>
      <div className={classes.dateContainer}>
        <DatePicker
          autoOk
          className={classes.datePicker}
          variant="inline"
          inputVariant="outlined"
          emptyLabel={t('dashboard:dates.startDate')}
          label={date.startDate ? t('dashboard:dates.startDate') : null}
          value={date.startDate}
          format="MM/dd/yyyy"
          onChange={(e) => changeDate('startDate', e)}
        />
        <DatePicker
          autoOk
          className={classes.datePicker}
          variant="inline"
          inputVariant="outlined"
          emptyLabel={t('dashboard:dates.endDate')}
          label={date.endDate ? t('dashboard:dates.endDate') : null}
          value={date.endDate}
          format="MM/dd/yyyy"
          onChange={(e) => changeDate('endDate', e)}
        />
        <Button
          className={classes.dateButton}
          value="Search"
          variant="outlined"
          color="secondary"
          onClick={() => fetchData(date.startDate, date.endDate)}
        >
          {t('common:actions.search')}
        </Button>
      </div>
      <div className={classes.bubbleContainer}>
        {dataBubbles.map((data, index) => (
          <div key={`${data.title}-${index}`} style={{ marginRight: '1.5rem' }}>
            <DataBubble
              spanColor={data.color}
              title={data.title}
              value={data.value}
              icon={data.icon}
            />
          </div>

        ))}
      </div>
      <DoubleAxisChart
        chartTitle={t('dashboard:postNumber')}
        chartData={chartData}
        leftLabel={t('dashboard:chart.leftLabel')}
        rightLabel={t('dashboard:chart.rightLabel')}
      />
      <TopPosts
        posts={topPosts}
        setPosts={setTopPosts}
      />
    </div>
  );
};

export default Dashboard;

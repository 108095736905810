const overrides = {
  MuiButton: {
    root: {
      borderRadius: '10px',
    },
  },
  MuiSelect: {
    outlined: {
      height: '40px',
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: '10px',
      border: '1px solid #D9E1E7',
      zIndex: '1',
    },
    elevation3: {
      boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.08)',
    },
  },
  MuiOutlinedInput: {
    root: {
      height: '40px',
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        border: '2px solid #2892EE',
      },
      color: '#4E6678',
    },
    input: {
      padding: '0 0 0 24px',
      height: '40px',
    },
    multiline: {
      height: '130px',
    },
    inputMultiline: {
      height: '100%',
    },
  },
  MuiInputLabel: {
    root: {
      color: '#5E7B91',
      fontWeight: 'bold',
      '&$focused': {
        color: '#5E7B91',
      },
    },
    outlined: {
      transform: 'translate(0px, -20px)',
    },
    shrink: {
      transform: 'translate(0px, -20px)',
    },
  },
};
export default overrides;

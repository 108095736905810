/* eslint-disable no-shadow */
import axios from 'axios';

require('dotenv').config();

const API = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const language = navigator.language || navigator.userLanguage;
const navLang = language.slice(0, 2);

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.request.status === 401 || error.request.status === 403) {
      localStorage.removeItem('user');
      window.location = `/${navLang}/login`;
    }
    return Promise.reject(error);
  },
);

const user = {
  logIn: (email, password, expire, recaptchaToken) => (
    API
      .post(
        '/user/log_in',
        {
          email, password, expire, recaptcha_token: recaptchaToken,
        },
      )
  ),
  createAccount: ({
    name, email, password, id, plan, recaptchaToken,
  }) => (
    API
      .post(
        '/account',
        {
          recaptcha_token: recaptchaToken,
          account: {
            timezone: 'Bogota',
          },
          user: {
            name,
            email,
            password,
            password_confirmation: password,
          },
          product: {
            name: 'My Product Release Notes',
            website: 'www.myproduct.com',
            description: 'My first product',
            product_uri: `my-first-product-${id}`,
          },
          plan,
        },
      )
  ),
  updateUser: (id, userInfo, authToken) => (
    API
      .patch(
        `/user/${id}`,
        userInfo,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  updatePassword: (id, passInfo, authToken) => (
    API
      .patch(
        `/user-password/${id}`,
        passInfo,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  paySubscription: (auth) => API.post('/user/pay-subscription', null, { headers: { Authorization: auth } }),
};

const product = {
  createProduct: (productData, authToken) => (
    API
      .post(
        '/product',
        productData,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  updateProduct: (id, product, authToken) => (
    API
      .put(
        `/product/${id}`,
        product,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  deleteProduct: (id, authToken) => (
    API
      .delete(
        `/product/${id}`,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  viewsBetween: (dates, authToken) => (
    API
      .post(
        '/views_between',
        dates,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  postsBetween: (dates, authToken) => (
    API
      .post(
        '/post_between',
        dates,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  topPosts: (dates, authToken) => (
    API
      .post(
        '/top_posts',
        dates,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  indexProduct: (page, perPage, authToken) => (
    API
      .get(
        `/product?page=${page}&per_page=${perPage}`,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  showProduct: (id, authToken) => (
    API
      .get(
        `/product/${id}`,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  showProductByURI: (uri, authToken) => (
    API
      .get(
        `/find-product/${uri}`,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  showDashboard: (dates, authToken) => (
    API
      .post(
        '/dashboard',
        dates,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  indexPosts: (page, rows, product, draft, authToken) => (
    API
      .get(
        '/post',
        {
          headers: { Authorization: authToken },
          params: {
            page,
            per_page: rows,
            product_id: product,
            draft,
          },
        },
      )
  ),
  createPost: (post, authToken) => (
    API
      .post(
        '/post',
        post,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  showPost: (id, delta, authToken) => (
    API
      .get(
        `/post/${id}`,
        {
          headers: { Authorization: authToken },
          params: { delta },
        },
      )
  ),
  updatePost: (id, post, authToken) => (
    API
      .put(
        `/post/${id}`,
        post,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  deletePost: (id, authToken) => API.delete(`/post/${id}`, {
    headers: {
      Authorization: authToken,
    },
  }),
  createCategory: (category, authToken) => (
    API
      .post(
        '/category',
        category,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  indexCategories: (page, rows, authToken) => (
    API
      .get(
        '/category',
        {
          headers: { Authorization: authToken },
          params: {
            page,
            per_page: rows,
          },
        },
      )
  ),
  showCategory: (id, authToken) => (
    API
      .get(
        `/category/${id}`,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  updateCategory: (id, category, authToken) => (
    API
      .put(
        `/category/${id}`, category,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  deleteCategory: (id, authToken) => (
    API
      .delete(
        `/category/${id}`,
        {
          headers: { Authorization: authToken },
        },
      )
  ),
  updateLogo: (logo, id, authToken) => (
    API
      .patch(
        `/product-logo/${id}/`,
        logo,
        {
          headers: {
            Authorization: authToken,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
  ),
  preSigned: (authToken, productId) => (
    API
      .get(
        `/pre-signed/${productId}`,
        { headers: { Authorization: authToken } },
      )
  ),
  updateWidget: ({ id, widget, authToken }) => (
    API
      .patch(
        `/update-widget/${id}/`,
        widget,
        { headers: { Authorization: authToken } },
      )
  ),
  productViews: ({
    postId, page, perPage, authToken,
  }) => API.get('/product_view', {
    headers: { Authorization: authToken },
    params: {
      post_id: postId,
      page,
      per_page: perPage,
    },
  }),
};

const utils = {
  allCategories: (authToken) => (
    API
      .get(
        '/utils/category',
        {
          headers: { Authorization: authToken },
        },
      )
  ),
};

const unregistered = {
  findPublishedPosts: (uri, page, rows, delta) => (
    API
      .get(
        `/find-posts/${uri}`,
        {
          params: {
            page,
            per_page: rows,
            delta,
          },
        },
      )
  ),
  recoverPassword: (email, recaptchaToken) => (
    API
      .post(
        '/user/forgot-password',
        null,
        {
          params: { email, recaptcha_token: recaptchaToken },
        },
      )
  ),
  resetPassword: (newPassword, auth) => (
    API
      .post(
        '/user/reset-password',
        newPassword,
        {
          headers: { Authorization: auth },
        },
      )
  ),
};

export default {
  user,
  product,
  utils,
  unregistered,
};

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Paper, Typography, Button } from '@material-ui/core';
import { Context } from '../../../context/AppContextProvieder';
import useStyles from './CreateCategoryStyle';
import CustomInput from '../CustomInput';
import ColorBubble from '../ColorBubble';
import isValid from '../../../utils/isValid';
import validation from '../../../utils/validation';
import constraints from './constraints';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import API from '../../../config/api';

const CreateCategory = ({
  createFunction, onClose, edit, categoryId, fetchCategories,
}) => {
  const classes = useStyles();
  const { product } = API;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['categories', 'common']);
  const [state, dispatch] = useContext(Context);
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState({
    name: '',
    color: '#000000',
  });
  const [errorsData, setErrorsData] = useState({
    name: null,
  });

  const fetchCategory = async () => {
    try {
      const { data } = await product.showCategory(categoryId, state.user.token);
      return data.data;
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 401 || error.request.status === 403) {
        enqueueSnackbar(
          t('common:snackbarMessages:expiredToken'),
          { variant: 'error' },
        );
        dispatch({ type: 'REMOVE_USER' });
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const updateCategory = async () => {
    try {
      await product.updateCategory(categoryId, {
        name: category.name,
        background_color: category.color,
      }, state.user.token);
      enqueueSnackbar(
        t('common:snackbarMessages.categories.updated'),
        { variant: 'success' },
      );
      fetchCategories();
      onClose();
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 401 || error.request.status === 403) {
        enqueueSnackbar(
          t('common:snackbarMessages:expiredToken'),
          { variant: 'warning' },
        );
        dispatch({ type: 'REMOVE_USER' });
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const destroyCategory = async () => {
    try {
      await product.deleteCategory(categoryId, state.user.token);
      enqueueSnackbar(
        t('common:snackbarMessages.categories.deleted'),
        { variant: 'success' },
      );
      fetchCategories();
      onClose();
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 401 || error.request.status === 403) {
        enqueueSnackbar(
          t('common:snackbarMessages:expiredToken'),
          { variant: 'warning' },
        );
        dispatch({ type: 'REMOVE_USER' });
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      const result = validation(e.target.name, e.target.value, constraints(t));

      setErrorsData({
        name: result,
      });

      setCategory({
        ...category,
        name: e.target.value,
      });
    } else {
      setCategory({
        ...category,
        color: e,
      });
    }
  };

  useEffect(() => {
    if (categoryId && edit) {
      fetchCategory()
        .then((response) => {
          setCategory({
            name: response.attributes.name,
            color: response.attributes.background_color,
          });
        });
    }
  }, [categoryId]);

  return (
    <div className={classes.paperContainer}>
      <ConfirmModal
        open={open}
        onClose={() => setOpen(false)}
        acceptFunc={destroyCategory}
        title={t('categories:deleteTitle')}
        body={t('categories:deleteDescription')}
      />
      <Paper className={classes.paper}>
        <div>
          <Typography variant="h2">{edit ? t('categories:edit') : t('categories:create')}</Typography>
          <CustomInput
            name="name"
            value={category.name}
            handleChange={handleChange}
            label={t('categories:columns.name')}
            marginValues="55px 0 0 0"
            errorMessage={errorsData.name}
          />
          <div className={classes.bubbleContainer}>
            <ColorBubble
              label={t('categories:columns.color')}
              color={category.color}
              setColor={handleChange}
              margin="35px 0 0 0"
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          {edit ? (
            <Button
              variant="outlined"
              className={classes.delButton}
              onClick={() => setOpen(true)}
            >
              {t('common:actions.delete')}

            </Button>
          ) : (<div />)}
          <div className={classes.rightBtnCont}>
            <Button
              className={classes.button}
              style={{ color: '#4E6678' }}
              onClick={onClose}
            >
              {t('common:actions.cancel')}

            </Button>
            <Button
              disabled={!isValid(category, constraints, t)}
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={edit ? () => updateCategory() : () => createFunction(category)}
            >
              {t('common:actions.save')}

            </Button>
          </div>
        </div>
      </Paper>

    </div>
  );
};

export default CreateCategory;

CreateCategory.propTypes = {
  createFunction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

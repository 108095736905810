const palette = {
  primary: {
    main: '#2892EE',
  },
  secondary: {
    main: '#4E6678',
  },
};

export default palette;

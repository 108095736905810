import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '35px',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  categoriesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftCard: {
    marginBottom: '15px',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
  },
  drawer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
}));

export default useStyles;

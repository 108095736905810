import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useStyles from './WidgetCodeStyle';

const WidgetCode = ({ token }) => {
  const classes = useStyles();
  const { t } = useTranslation(['widget', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const { lang } = useParams();

  const code = (
    `
    <div id="rp-token" class="rp-container" data-param="${token}" lang="${lang}" data-user-metadata=""></div>
    <script type="text/javascript" src="https://widget.releasepad.io/releasepad-bundle.js" charset="utf-8"></script>
    <link rel="stylesheet" href="https://widget.releasepad.io/releasepad-bundle.min.css">
    `);

  const copyCode = () => {
    enqueueSnackbar(
      t('common:actions.copyClipboard'),
      { variant: 'success' },
    );
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <Typography className={classes.body}>
        {t('widget:instructions')}
      </Typography>
      <div className={classes.codeBox}>
        <pre className={classes.codeBody}>
          {code}
        </pre>
        <CopyToClipboard
          text={code}
          onCopy={copyCode}
        >
          <CopyIcon className={classes.copyIcon} />
        </CopyToClipboard>

      </div>
    </div>
  );
};

export default WidgetCode;

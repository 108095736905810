import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: '35px',
    marginBottom: '25px',
  },
  button: {
    width: '177px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  delButton: {
    width: '177px',
    color: 'red',
    borderColor: 'red',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

export default useStyles;

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroller';
import {
  Typography,
  Drawer,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import NoPostsImg from '../../../assets/Illustration_Notfound.svg';
import useStyles from './WidgetPreviewStyle';
import WidgetPost from '../WidgetPost';
import PublishedPost from '../PublishedPost';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import mapPosts from '../../../utils/mapPosts';

const loadingPosts = (
  <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
    <CircularProgress />
  </div>
);

const WidgetPreview = ({ open, setOpen }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const { product } = API;

  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const { widget } = state.product.attributes;
  const currentProduct = state.product;
  const [posts, setPosts] = useState([]);
  const [postData, setPostData] = useState();
  const [viewMode, setViewMode] = useState('index');
  const [hasMore, setHasMore] = useState(true);

  const fetchPosts = async (page) => {
    if (!hasMore) return;
    try {
      const { data } = await product.indexPosts(page, 10, currentProduct.id, false, state.user.token);
      const published = mapPosts(data);
      setPosts((oldPosts) => [...oldPosts, ...published]);
      if (data.meta.last_page) setHasMore(false);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const getPost = async (postId) => {
    try {
      const { data } = await product.showPost(postId, true, state.user.token);
      setPostData({
        title: data.data.attributes.title,
        body: data.data.attributes.delta,
        creation: data.data.attributes.publish_at,
        category: data.data.attributes.category_name,
        color: data.data.attributes.category_color,
        is_private: data.data.attributes.is_private,
        show_only_to_ids: data.data.attributes.show_only_to_ids,
      });
      setViewMode('post');
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setViewMode('index');
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      classes={{
        paper: clsx({
          [classes.lightDrawer]: true,
          [classes.darkDrawer]: widget.dark_mode,
        }),
      }}
    >
      <div className={classes.titleContainer} style={{ backgroundColor: widget.color }}>
        {viewMode === 'post' && (
          <ArrowBackIcon
            className={classes.arrowBack}
            onClick={() => setViewMode('index')}
          />
        )}
        <Typography
          className={classes.titleLight}
          variant="h4"
        >
          {viewMode === 'index' ? widget.title : postData.title}
        </Typography>
      </div>
      <div className={
        clsx({
          [classes.light]: true,
          [classes.dark]: widget.dark_mode,
        })
      }
      >
        <CloseIcon onClick={() => setOpen(false)} fontSize="small" className={classes.closeIcon} />
        {viewMode === 'post' && (
          <div style={{ marginTop: '20px', width: '100%' }}>
            <PublishedPost
              title={postData.title}
              delta={postData.body}
              creation={postData.creation}
              category={postData.category}
              color={postData.color}
              darkTheme={widget.dark_mode}
            />
          </div>
        )}
        {viewMode === 'index' && (
          <InfiniteScroll
            loadMore={fetchPosts}
            hasMore={hasMore}
            loader={!hasMore ? loadingPosts : null}
            useWindow={false}
          >
            {posts.map((post) => (
              <WidgetPost
                key={post.id}
                id={post.id}
                category={post.category}
                categoryColor={post.color}
                date={post.creation}
                postTitle={post.title}
                postBody={post.blurb}
                getPost={getPost}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </Drawer>
  );
};

export default WidgetPreview;

WidgetPreview.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

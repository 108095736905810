import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Paper } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import useStyles from './WidgetPostStyle';
import { Context } from '../../../context/AppContextProvieder';

const WidgetPost = ({
  id, category, categoryColor, date, postTitle, postBody, getPost,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['widget']);
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const { widget } = state.product.attributes;
  const userLang = navigator.language || navigator.userLanguage;
  const creationTmp = new Date(date);
  const localeDate = creationTmp.toLocaleString(userLang, { dateStyle: 'medium' });

  return (
    <div style={{ width: '100%' }}>
      <Paper className={
        clsx({
          [classes.paperLight]: true,
          [classes.paperDark]: widget.dark_mode,
        })
}
      >
        <div className={classes.categoryContainer}>
          <div className={classes.category} style={{ backgroundColor: categoryColor }}>
            <Typography className={classes.categoryLabel}>{category}</Typography>
          </div>
          <Typography
            className={
            clsx({
              [classes.lightDate]: true,
              [classes.darkDate]: widget.dark_mode,
            })
          }
            variant="subtitle1"
          >
            {localeDate}

          </Typography>
        </div>
        <Typography
          className={
            clsx({
              [classes.darkText]: widget.dark_mode,
            })
          }
          style={{ margin: '10px 0' }}
          variant="h5"
        >
          {postTitle}

        </Typography>
        <Typography
          style={{ color: widget.dark_mode ? 'white' : '#4E6678' }}
        >
          {postBody}
        </Typography>
        <div
          onClick={() => getPost(id)}
          className={classes.moreContainer}
        >
          <Typography
            color="primary"
            style={{ fontWeight: 700, fontSize: '12px' }}
          >
            {t('widget:readMore')}
          </Typography>
          <ArrowIcon color="primary" style={{ fontSize: '12px', marginLeft: '5px' }} />
        </div>
      </Paper>
    </div>
  );
};

export default WidgetPost;

WidgetPost.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryColor: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  postTitle: PropTypes.string.isRequired,
  postBody: PropTypes.string.isRequired,
  getPost: PropTypes.func.isRequired,
};

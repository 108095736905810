import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ' 100px',
    width: '100%',
    borderRadius: '10px',
    position: 'relative',
    boder: '1px solid #D9E1E7',
  },

  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'absolute',
    borderRadius: '10px',
  },

  paperBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },

  productTitle: {
    marginTop: '15px',
    fontSize: '17px',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontWeight: 600,
    color: '#5E7B91',
  },

  descriptionText: {
    fontSize: '12px',
    marginTop: '10px',
  },

  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },

  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#90A6B7',
  },

  dataText: {
    fontSize: '12px',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
  },

  editIcon: {
    color: '#2892EE',
    margin: '5px 5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },

}));

export default useStyles;

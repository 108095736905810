const constraints = (t) => ({
  user: {
    presence: {
      message: t('common:validations:presence'),
      allowEmpty: false,
    },
    email: {
      message: t('common:validations:email'),
    },
  },
  password: {
    presence: {
      message: t('common:validations:presence'),
      allowEmpty: false,
    },
  },
});

export default constraints;

export default {
  title: 'Mi cuenta',
  tabs: {
    info: 'Información general',
    security: 'Seguridad',
    subscription: 'Subscripción',
    invoice: 'Factura',
  },
  userInfo: {
    name: 'Nombre',
    email: 'Email',
    company: 'Compañía',
    success: 'Su información ha sido actualizada',
  },
  security: {
    currPassword: 'Contraseña actual',
    newPassword: 'Nueva contraseña',
    confPassword: 'Confirmar contraseña',
    success: 'Su contraseña ha sido actualizada',
  },
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: '35px',
    backgroundColor: '#F5F7F8',
    flex: 1,
    display: 'flex',
    // maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '25px',
    },
  },
  paperCard: {
    padding: '35px 45px',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '25px 35px',
    },
  },
  stepper: {
    padding: '0px',
    marginTop: '25px',
    marginBottom: '10px',
    width: '50%',
    // [theme.breakpoints.down]
  },
  step: {
    padding: '0px 5px 0px 0px',
    color: 'red',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  stepLabel: {
    color: '#FEBE58',
    fontWeight: 'bold',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: 20,
  },
  scientistImg: {
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  widgetContainer: {
    display: 'flex',
    width: '800px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      width: '440px',
    },
    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth - 130,
    },
  },
  bottomContainer: {
    display: 'flex',
    marginTop: '45px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '50px',
    flexWrap: 'wrap',
  },
  button: {
    width: '177px',
    marginLeft: '35px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: '15px',
    },
  },
  siteReady: {
    color: '#4E6678',
    marginTop: '15px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  uri: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
  },
  paperImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
    width: '200px',
    borderRadius: '10px',
    padding: '0px',
    cursor: 'pointer',
  },
  imageContainer: {
    cursor: 'pointer',
    maxHeight: '100px',
  },
  file: {
    display: 'none',
  },
  logo: {
    maxWidth: '100%',
    borderRadius: '10px',
    maxHeight: '90px',
  },
}));

export default useStyles;

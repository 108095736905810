import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EDF0F2',
    width: '100%',
  },
  bar: {
    backgroundColor: '#212852',
  },
  logo: {
    height: '60px',
    cursor: 'pointer',
  },
  separator: {
    width: '1px',
    height: '15px',
    backgroundColor: '#BABFC4',
    margin: '0 15px',
  },
  container: {
    width: '80%',
    height: '85%',
    paddingTop: '45px',
    margin: 'auto',
    backgroundColor: '#EDF0F2',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      height: '90%',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '35px',
  },
  widgetLogo: {
    maxHeight: '100px',
    marginRight: '15px',
    borderRadius: '15px',
  },
  notFoundText: {
    marginTop: '20px',
    color: '#161C22AF',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '30px 0',
  },
  icon: {
    marginLeft: '10px',
    cursor: 'pointer',
  },
}));

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    width: '170px',
    height: '70px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '60px',
      marginTop: '15px',
    },
  },
  colorSpan: {
    width: '6px',
    height: 'inherit',
    position: 'fixed',
    transform: 'translateX(-92px)',
    borderRadius: '50px 0 0 50px',
  },
  bubbleData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
} from '@material-ui/core';
import { Context } from '../../../context/AppContextProvieder';
import WidgetCode from '../WidgetCode';

const Widget = () => {
  const { t } = useTranslation(['widget']);
  const [state, dispatch] = React.useContext(Context);
  const { product } = state;
  return (
    <div>
      <Typography variant="h2">{t('widget:code')}</Typography>
      <WidgetCode token={product.attributes.public_token} />
    </div>
  );
};

export default Widget;

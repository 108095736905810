export default {
  january: 'Jan',
  february: 'Feb',
  march: 'Mar',
  april: 'Apr',
  may: 'May',
  june: 'Jun',
  july: 'Jul',
  august: 'Aug',
  september: 'Sept',
  october: 'Oct',
  november: 'Nov',
  december: 'Dec',
};

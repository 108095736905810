export default {
  products: 'My products',
  dashboard: 'Dashboard',
  posts: 'Posts',
  settings: 'General settings',
  widget: 'widget',
  categories: 'Categories',
  invalidSize: 'Invalid logo size',
  width: 'Width between',
  height: 'Height between',
  preview: 'Widget preview',
  releaseNotes: 'View release notes',
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chartPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0',
    marginBottom: '35px',
  },
  chartTitle: {
    alignSelf: 'flex-start',
    marginBottom: '25px',
    marginLeft: '30px',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}));

export default useStyles;

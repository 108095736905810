import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  container: {
    padding: '35px',
    backgroundColor: '#F5F7F8',
    flex: 1,
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '700px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '25px',
    },
  },
  paper: {
    padding: '35px 45px',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '25px 35px',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  blurb: {
    color: '#4E6678',
  },
  closeIcon: {
    cursor: 'pointer'
  },
  loaderContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center'
  },
  noViewscont: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  noViewsText: {
    color: '#4E6678',
    marginTop: '10px'
  },
  tableContainer: {
    marginTop: '40px'
  },
  tableHeader: {
    boxShadow: '0px 0px 10px #D3D3D3'
  },
  tableCell: {
    color: '#4E6678',
  },
  paginationCont: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default useStyles;

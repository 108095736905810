import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Typography,
  Button,
  Drawer,
  Tabs,
  Tab,
  CircularProgress,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
// import SearchIcon from '@material-ui/icons/Search';
import PostCard from '../PostCard';
import useStyles from './PostsStyle';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import CreatePost from '../CreatePost';
import UserViews from '../UserViews';
import NoPostsImg from '../../../assets/Illustration_Notfound.svg';
import mapPosts from '../../../utils/mapPosts';
import { POSTS_PER_PAGE } from '../../../utils/constants';
import getBlurb from '../../../utils/getBlurb';

const Posts = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'posts']);
  const { product, utils } = API;
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const currentProduct = state.product;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [posts, setPosts] = useState([]);
  const [postCategories, setPostCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewsPost, setViewsPost] = useState({
    id: null,
    title: null,
    blurb: null,
  });
  const [viewsDrawer, setViewsDrawer] = useState(false);
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [editPost, setEditPost] = useState(false);
  const [currPost, setCurrPost] = useState();
  const [postsCount, setPostsCount] = useState({
    published: null,
    drafts: null,
  });

  const newPostObject = () => ({
    title: '',
    category: '',
    delta: {
      time: Date.now(),
      blocks: [
        {
          type: 'paragraph',
          data: {
            text: t('posts:cardTitles.defaultPostBody'),
            level: 1,
          },
        },
      ],
      version: '2.18.0',
    },
    publish_at: new Date(),
    draft: false,
  });

  const [newPost, setNewPost] = useState(newPostObject());

  const closeDrawer = () => {
    setOpen(false);
    setEditPost(false);
  };

  const fetchPosts = async (draft) => {
    try {
      const { data } = await product.indexPosts(page, POSTS_PER_PAGE, currentProduct.id, draft, state.user.token);
      setPostsCount({
        published: data.meta.published_posts,
        drafts: data.meta.draft_posts,
      });
      setTotalPages(data.meta.total_pages);
      const postsTmp = mapPosts(data);
      setPosts(postsTmp);
      setIsLoading(false);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const categoryNameToValue = (categoryName, categoryArray) => {
    const categoryTmp = categoryArray.find((category) => (category.label === categoryName));
    return categoryTmp.value;
  };

  const getPost = async (postId) => {
    try {
      const { data } = await product.showPost(postId, true, state.user.token);
      setCurrPost(data.data.id);
      setNewPost({
        id: data.data.id,
        title: data.data.attributes.title,
        category: categoryNameToValue(data.data.attributes.category_name, postCategories),
        delta: data.data.attributes.delta,
        publish_at: data.data.attributes.publish_at,
        draft: data.data.attributes.draft,
        is_private: data.data.attributes.is_private,
        only_show_to_ids: data.data.attributes.only_show_to_ids,
      });
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await utils.allCategories(state.user.token);
      const categories = [];

      Object.entries(data).forEach(([key, value]) => {
        categories.push({ label: key, value });
      });
      setPostCategories(categories);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const fetchFunction = () => {
    if (tab === 0) {
      fetchPosts(false);
    } else {
      fetchPosts(true);
    }
  };

  const deletePost = async () => {
    try {
      await product.deletePost(newPost.id, state.user.token);
      fetchFunction();
      closeDrawer();
      enqueueSnackbar(
        t('posts:deleted'),
        { variant: 'success' },
      );
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 422) {
        enqueueSnackbar(
          t('common:snackbarMessages:formError'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const createNewPost = async (delta) => {
    try {
      await product.createPost({
        title: newPost.title,
        blurb: getBlurb(delta),
        delta,
        draft: newPost.draft,
        publish_at: newPost.publish_at,
        category_id: newPost.category,
        product_id: currentProduct.id,
        is_private: newPost.is_private,
        only_show_to_ids: newPost.only_show_to_ids,
      }, state.user.token);
      fetchFunction();
      closeDrawer();
      enqueueSnackbar(
        t('posts:created'),
        { variant: 'success' },
      );
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 422) {
        enqueueSnackbar(
          t('common:snackbarMessages:formError'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const handleChangePage = (event, selectedPage) => {
    setPage(selectedPage);
  };

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
    setPage(1);
  };

  const openEditPost = async (postId) => {
    setEditPost(true);
    await getPost(postId);
    setOpen(true);
  };

  const openViews = (post) => {
    setViewsPost({ ...post });
    setViewsDrawer(true);
  };

  const renderPosts = () => {
    if (posts.length > 0) {
      return (
        posts.map((post) => (
          <PostCard
            editPost={openEditPost}
            key={post.id}
            id={post.id}
            title={post.title}
            blurb={post.blurb}
            views={post.views}
            creation={post.creation}
            author={post.author}
            category={post.category}
            color={post.color}
            categories={postCategories}
            openViews={openViews}
          />
        ))
      );
    }

    return (
      <div className={classes.notFoundContainer}>
        <img src={NoPostsImg} alt="Missing" width={250} />
        {tab === 0 && (
          <Typography align="center" className={classes.notFoundText}>
            {t('posts:notFound.partOne')}
            <span
              style={{ color: '#2892EE', fontWeight: 'bold' }}
            >
              {t('common:actions.createPost')}
            </span>
            {t('posts:notFound.partTwo')}
          </Typography>
        )}
      </div>
    );
  };

  const editPostFunc = async (delta) => {
    try {
      await product.updatePost(currPost, {
        title: newPost.title,
        blurb: getBlurb(delta),
        delta,
        draft: newPost.draft,
        publish_at: newPost.publish_at,
        category_id: newPost.category,
        product_id: currentProduct.id,
        is_private: newPost.is_private,
        only_show_to_ids: newPost.only_show_to_ids,
      }, state.user.token);
      setEditPost(false);
      setOpen(false);
      setTab(0);
      fetchPosts();
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const handleCreateOpen = () => {
    setNewPost(newPostObject());
    setOpen(true);
  };

  useEffect(() => {
    fetchFunction();
    // eslint-disable-next-line
  }, [tab, page]);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    window.scrollTo(0, 0);
    fetchCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    renderPosts();
    // eslint-disable-next-line
  }, [posts]);

  if (isLoading) {
    return (
      <div className={classes.loadingRoot}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <UserViews
        open={viewsDrawer}
        post={viewsPost}
        closeDrawer={() => setViewsDrawer(false)}
      />
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        anchor="right"
        open={open}
        ModalProps={{
          disableAutoFocus: true,
          disableEnforceFocus: true
        }}
        onClose={closeDrawer}
      >
        <CreatePost
          cardTitle={
            editPost
              ? t('posts:cardTitles.editTitle')
              : t('posts:cardTitles.createTitle')
          }
          newPost={newPost}
          setNewPost={setNewPost}
          createPost={editPost ? editPostFunc : createNewPost}
          categories={postCategories}
          deleteFunction={editPost && deletePost}
          cancelFunction={closeDrawer}
          user={state.user}
        />
      </Drawer>
      <div className={classes.titleContainer}>
        <Typography variant="h2">{t('common:nouns.posts')}</Typography>
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={handleCreateOpen}
        >
          {t('common:actions.createPost')}
        </Button>
      </div>

      <Tabs
        style={{ marginBottom: '35px' }}
        value={tab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant={windowWidth <= 600 && 'fullWidth'}
        TabIndicatorProps={{
          style: {
            height: '4px',
          },
        }}
      >
        <Tab label={`${t('posts:tabs.published')} (${postsCount.published})`} />
        <Tab label={`${t('posts:tabs.drafts')} (${postsCount.drafts})`} />
      </Tabs>
      {/* <div className={classes.iconsContainer}>
        <TextField variant="outlined" />
        <SearchIcon color="primary" className={classes.icon} />
      </div> */}
      {renderPosts()}
      {
        totalPages > 0 && (
          <div className={classes.pagination}>
            <Pagination
              color="primary"
              count={totalPages}
              boundaryCount={4}
              page={page}
              onChange={handleChangePage}
            />
          </div>
        )
      }
    </div>
  );
};

export default Posts;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 35,
  },
  card: {
    padding: '30px',
    cursor: 'pointer',
    marginBottom: 20,
  },
  postBody: {
    margin: '20px 0',
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  viewsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px'
    }
  },
  dateTypography: {
    color: '#BABFC4',
  },
  icon: {
    marginRight: '10px',
    color: '#4E6678',
    fontSize: '1rem',
  },
  iconText: {
    color: '#4E6678',
    fontSize: '1rem',
    fontWeight: 700,
    marginRight: '10px'
  },
  drawer: {
    // padding: '35px',
    // paddingBottom: '0px',
    // backgroundColor: '#F5F7F8',
    // display: 'flex',
    // flex: 1,
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#F5F7F8',
  },
  subtitle: {
    color: '#BABFC4',
  },
}));

export default useStyles;

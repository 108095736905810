import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import useStyles from './PasswordStyle';

const Password = ({
  name, value, label, handleChange, errorMessage, marginValues,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const ref = React.useRef();

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => (!showPassword));
  };

  return (
    <FormControl
      ref={ref}
      style={{
        margin: marginValues,
        width: '100%',
      }}
      fullWidth
      variant="outlined"
    >
      <InputLabel className={classes.label} shrink={false} htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        error={!!errorMessage}
        name={name}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleClickShowPassword()}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        )}
      />
      {
        errorMessage
        && (
          <FormHelperText
            error
          >
            {errorMessage}
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

export default Password;

Password.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  marginValues: PropTypes.string,
};

Password.defaultProps = {
  errorMessage: '',
  marginValues: '0',
};

import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Typography, Tabs, Tab, Button,
} from '@material-ui/core';
import useStyles from './GeneralSettingsStyle';
import TabPanel from '../TabPanel';
import BrandIdentity from '../BrandIdentity';
import Widget from '../Widget';
import Categories from '../Categories';
import WidgetStyling from '../WidgetStyling/WidgetStyling';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { Context } from '../../../context/AppContextProvieder';
import api from '../../../config/api';
import createWidgetScript from '../../../utils/createWidgetScript';
import { actions } from '../../../context/Reducer';

const GeneralSettings = () => {
  const [state, dispatch] = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation(['settings', 'common', 'product', 'brand']);
  const classes = useStyles();
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);
  const { product } = api;
  const { lang } = useParams();
  const { widget } = state.product.attributes;
  const [widgetParams, setWidgetParams] = useState({ ...widget });

  const changeTab = (event, tab) => {
    setTab(tab);
  };

  const saveWidgetConfig = async () => {
    try {
      const { data } = await product.updateWidget({
        id: state.product.id,
        widget: {
          title: widgetParams.title,
          color: widgetParams.color,
          dark_mode: widgetParams.dark_mode,
          custom_css: widgetParams.custom_css,
        },
        authToken: state.user.token,
      });
      createWidgetScript();
      dispatch({ type: actions.SET_PRODUCT, payload: data.data });
      enqueueSnackbar(
        t('common:snackbarMessages.settingsUpdated'),
        { variant: 'success' },
      );
    } catch (error) {
      enqueueSnackbar(
        t('common:snackbarMessages.settingsFail'),
        { variant: 'warning' },
      );
    }
  };

  const destroyProduct = async () => {
    try {
      await product.deleteProduct(state.product.id, state.user.token);
      history.push(`/${lang}/products`);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ConfirmModal
        open={open}
        onClose={() => setOpen(false)}
        title={t('product:card.deleteTitle')}
        body={t('product:card.deleteDescription')}
        acceptFunc={destroyProduct}
      />
      <Typography variant="h2">{t('title')}</Typography>
      <Tabs
        className={classes.tabs}
        value={tab}
        textColor="primary"
        indicatorColor="primary"
        variant={windowWidth <= 600 && 'fullWidth'}
        onChange={changeTab}
        TabIndicatorProps={{
          style: {
            height: '4px',
          },
        }}
      >
        <Tab label={t('settings:tabs.widget')} />
        <Tab label={t('brand:styling')} />
        <Tab label={t('settings:tabs.categories')} />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <div style={{ marginBottom: '60px' }}>
          <BrandIdentity
            widgetParams={widgetParams}
            setWidgetParams={setWidgetParams}
          />
        </div>
        <Widget />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <WidgetStyling
          widgetParams={widgetParams}
          setWidgetParams={setWidgetParams}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Categories />
      </TabPanel>
      {tab === 0 && (
        <div className={classes.btnsContainer}>
          <Button
            className={classes.delButton}
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            {t('common:actions.delete')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={saveWidgetConfig}
          >
            {t('brand:save')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GeneralSettings;

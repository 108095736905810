import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import useStyles from './CustomInputStyle';

const CustomInput = ({
  name, value, label, handleChange, marginValues, errorMessage, multiline, rows,
}) => {
  const classes = useStyles();
  return (
    <FormControl style={{ margin: marginValues }} className={classes.root} variant="outlined">
      <InputLabel shrink={false} htmlFor="outlined-adornment-input">{label}</InputLabel>
      <OutlinedInput
        multiline={multiline}
        rows={multiline ? rows : null}
        error={!!errorMessage}
        name={name}
        value={value}
        onChange={handleChange}
      />
      {
        errorMessage
        && (
          <FormHelperText
            error
          >
            {errorMessage}
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

export default CustomInput;

CustomInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  marginValues: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

CustomInput.defaultProps = {
  multiline: false,
  rows: 5,
  errorMessage: '',
};

import React, { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  Drawer,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Account from '../Account';
import logo from '../../../assets/logo-white-transparent.png';
import useStyles from './BarStyle';
import { Context } from '../../../context/AppContextProvieder';
import { actions } from '../../../context/Reducer';
import createWidgetScript from '../../../utils/createWidgetScript';
import SideBarDrawer from '../SideBarDrawer';

const Bar = ({ siteSelected }) => {
  const classes = useStyles();
  const history = useHistory();
  const { lang, uri } = useParams();
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const { t } = useTranslation(['navbar']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openNavbar, setOpenNavbar] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch({ type: actions.REMOVE_USER });
    handleClose();
    history.push(`/${lang}/`);
  };

  React.useEffect(() => {
    createWidgetScript();
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);

  return (
    <div>
      <Drawer
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => setOpen(false)}
      >
        <Account setOpen={setOpen} />
      </Drawer>
      <Drawer
        anchor="left"
        open={openNavbar}
        onClose={() => setOpenNavbar(false)}
      >
        <SideBarDrawer closeMenu={() => setOpenNavbar(false)} />
      </Drawer>
      <AppBar classes={{ root: classes.bar }}>
        <Toolbar>
          <div className={classes.logoContainer}>
            <div className={classes.imageContainer}>
              <img onClick={() => history.push(`/${lang}/products`)} className={classes.logo} src={logo} alt="" />
            </div>
            <div
              onClick={() => history.push(`/${lang}/products`)}
              className={classes.menuItemsContainer}
            >
              <Typography
                variant="h6"
                className={
                  clsx({
                    [classes.menuItem]: true,
                    [classes.menuSelected]: siteSelected,
                  })
                }
              >
                {t('products')}
              </Typography>
            </div>
          </div>

          <Typography variant="h6" className={classes.name}>
            {`${t('greeting')} ${state.user.user.name}!`}
          </Typography>
          {(windowWidth <= 960 && uri) && (
            <IconButton onClick={() => setOpenNavbar(true)}>
              <MenuIcon className={classes.icon} />
            </IconButton>
          )}
          <IconButton aria-label="show 4 new mails" color="primary" onClick={handleClick}>
            <Badge classes={{ root: classes.icon }} color="primary">
              <PersonIcon />
            </Badge>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ transform: 'translate(0, 40px)' }}
            classes={{ paper: classes.paper }}
          >
            <MenuItem onClick={() => setOpen(true)} className={classes.dropDownItem}>
              <ListItemIcon>
                <PersonIcon className={classes.dropDownItemIcon} />
              </ListItemIcon>
              <Typography>{t('account')}</Typography>
            </MenuItem>

            <MenuItem onClick={logOut} className={classes.dropDownItem}>
              <ListItemIcon>
                <ExitToAppIcon className={classes.dropDownItemIcon} />
              </ListItemIcon>
              <Typography>{t('logOut')}</Typography>
            </MenuItem>
          </Menu>
          <div style={{ width: 25, height: 25 }}>
            <div
              id="release-pad-widget"
              className="rp-container"
              data-user-metadata={state.user.user.email}
              lang={lang}
              data-param="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcm9kdWN0X2lkIjoyMH0.AojEjHKFX3iQ-KgHHmQCOsqV8r_J2mjKr84OzROtGE8"
            />
            <link rel="stylesheet" href="https://widget.releasepad.io/releasepad-bundle.min.css" />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Bar;

Bar.propTypes = {
  siteSelected: PropTypes.bool.isRequired,
};

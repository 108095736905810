export default {
  dataBubbles: {
    views: 'Views to date',
    published: 'Published posts',
  },
  editButton: 'Edit product',
  dashboard: 'Dashboard',
  mySites: 'My sites',
  topPosts: {
    header: 'Most viewed posts',
    views: 'Views',
    title: 'Title',
    body: 'Description',
  },
  postNumber: 'Number of posts and views',
  dates: {
    startDate: 'Start date',
    endDate: 'End date',
  },
  chart: {
    leftLabel: 'Posts',
    rightLabel: 'Views',
  },
};

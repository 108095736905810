/* eslint-disable react/jsx-props-no-spreading */
// React
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

// React router
import { Route, Redirect, useParams } from 'react-router-dom';

// Services
import { Context } from '../context/AppContextProvieder';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const { lang } = useParams();

  useEffect(() => {
    const { user } = state;
    const elements = {
      'tb-user-id': user.user.id,
      'tb-full-name': user.user.name,
      'tb-email': user.user.email,
    }
    Object.entries(elements).forEach(([key, value]) => {
      const input = document.createElement('input')
      input.setAttribute('id', key);
      input.setAttribute('name', value);
      input.setAttribute('type', 'hidden')
      document.body.appendChild(input);
    })
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => (
        state.user
          ? <Component {...props} />
          : <Redirect to={`/${lang}/login`} />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.element.isRequired,
};
export default PrivateRoute;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '10px',
    // width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  colorSquare: {
    width: '50px',
    height: '50px',
    borderRadius: '10px',
    marginRight: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pickerContainer: {
    marginTop: '10px',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

export default useStyles;

const storeUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const getUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user || null;
};

const removeUser = () => {
  localStorage.removeItem('user');
};

export { storeUser, getUser, removeUser };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    flexGrow: 1,
    display: 'flex',
    margin: '0 50px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logo: {
    height: '50px',
  },
  menuItemsContainer: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    height: '60px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 150,
    marginLeft: '50px',
    fontSize: '16px',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontWeight: 'bold',
    height: '100%',
    '&:hover': {
      background: 'rgba(40, 146, 238, 0.04)',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px',
    },
  },
  menuSelected: {
    borderBottomStyle: 'solid',
    borderBottomColor: '#2892EE',
  },
  name: {
    fontSize: '15px',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bar: {
    backgroundColor: '#000614',
    zIndex: 100,
  },
  icon: {
    color: '#FFFFFF',
  },
  paper: {
    borderRadius: 0,
    boxShadow: '0px 3px 6px #00000029',
  },
  dropDownItem: {
    color: '#374757',
  },
  dropDownItemIcon: {
    color: '#374757',
  },
  drawerPaper: {
    // width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
}));

export default useStyles;

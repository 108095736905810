import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  Link,
} from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Password from '../../components/Password';
import CustomInput from '../../components/CustomInput';
import Footer from '../../components/Footer';
import Image from '../../../assets/planet-register.png';
import logo from '../../../assets/new-logo.png';
import useStyles from './RegisterStyle';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import { actions } from '../../../context/Reducer';
import validation from '../../../utils/validation';
import constraints from './constraints';
import isValid from '../../../utils/isValid';
import CustomCircularProgress from '../../components/CustomCircularProgress';
import { Mixpanel } from '../../../utils/Mixpanel';

// Destructuring
const { user } = API;

const Register = () => {
  const classes = useStyles();
  const { lang } = useParams();
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['register', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const checkoutUrl = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    password: '',
    plan: 'starter'
  });

  const [errorsData, setErrorsData] = useState({
    name: null,
    company: null,
    email: null,
    password: null,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleChange = (event) => {
    const result = validation(event.target.name, event.target.value, constraints(t));

    setErrorsData({
      ...errorsData,
      [event.target.name]: result,
    });

    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const logIn = async (token) => {
    try {
      const { data } = await user.logIn(formData.email, formData.password, false, token);
      dispatch({ type: actions.SET_USER, payload: data });
      enqueueSnackbar(
        t('common:snackbarMessages:successLogIn'),
        { variant: 'success' },
      );
      window.location.href = checkoutUrl.current;
    } catch (error) {
      setIsLoading(false);
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const verifyRecaptchaLogIn = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
    }
    const token = await executeRecaptcha('SignIn');
    logIn(token);
  };

  const pushEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "sign_up"
    })
  }

  const create = async (token) => {
    setIsLoading(true);
    const id = makeid(6);
    try {
      const { data } = await user.createAccount({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        id,
        plan: formData.plan,
        recaptchaToken: token,
      });
      Mixpanel.track('SignUp');
      const { checkout_session_url } = data;
      checkoutUrl.current = checkout_session_url;
      pushEvent();
      verifyRecaptchaLogIn();
    } catch (error) {
      if (error.request.status === 422) {
        enqueueSnackbar(
          t('common:snackbarMessages:emailTaken'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:registrationError'),
          { variant: 'warning' },
        );
      }
      setIsLoading(false);
    }
  };

  const verifyRecaptchaSignUp = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
    }
    const token = await executeRecaptcha('SignUp');
    create(token);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const selectedPlan = urlParams.get('plan');
    if (selectedPlan) {
      setFormData((data) => ({
        ...data,
        plan: selectedPlan
      }))
    }
  }, [])

  if (isLoading) {
    return <CustomCircularProgress />;
  }
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <img className={classes.titleLogo} src={logo} alt="" />
          <Divider style={{ margin: '0 25px' }} orientation="vertical" flexItem />
          <Typography className={classes.titleTypography} variant="h2">{t('register:register')}</Typography>
        </div>
        <Paper elevation={3} className={classes.paper}>
          <Grid container spacing={7}>
            <Grid className={classes.gridItem} item xs={12} md={6}>
              <form className={classes.form}>
                <CustomInput
                  name="name"
                  label={t('register:formData.name')}
                  value={formData.name}
                  handleChange={handleChange}
                  marginValues="0 0 35px 0"
                  errorMessage={errorsData.name}
                />
                <CustomInput
                  name="email"
                  label={t('register:formData.email')}
                  value={formData.email}
                  handleChange={handleChange}
                  marginValues="0 0 35px 0"
                  errorMessage={errorsData.email}
                />
                <Password
                  name="password"
                  label={t('register:formData.password')}
                  value={formData.password}
                  handleChange={handleChange}
                  errorMessage={errorsData.password}
                />
                <Button
                  type="submit"
                  disableElevation
                  disabled={!isValid(formData, constraints, t)}
                  style={{ marginTop: '30px', width: '177px' }}
                  variant="contained"
                  color="primary"
                  onClick={verifyRecaptchaSignUp}
                >
                  {t('register:register')}
                </Button>
              </form>
              <div className={classes.actionsContainer}>
                <Typography className={classes.actionsQuestion}>{t('register:login.question')}</Typography>
                <Link component={RouterLink} to={`/${lang}/login`}>
                  <Typography style={{ fontWeight: 'bold' }}>{t('register:login.answer')}</Typography>
                </Link>
              </div>
            </Grid>
            <Grid className={classes.gridItem} item xs={12} md={6}>
              <img className={classes.image} src={Image} alt="La imagen no está disponible." />
            </Grid>
          </Grid>
        </Paper>
        <Typography className={classes.recaptchaText}>
          This site is protected by reCAPTCHA and the Google
          {' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a>
          {' '}
          and
          {' '}
          <a href="https://policies.google.com/terms">Terms of Service</a>
          {' '}
          apply.
        </Typography>
      </div>

      <Footer />
    </div>
  );
};

export default Register;

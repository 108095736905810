import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import useStyles from './ProductItemStyle';
import noImage from '../../../assets/Illustration_Notfound.svg';

const ProductItem = ({
  product, name, count, showProduct,
}) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={3}
      className={classes.paper}
      onClick={() => showProduct(product)}
    >
      <div className={classes.imageContainer}>
        <img className={classes.image} src={product.attributes.logo_url || noImage} alt="" />
      </div>
      <div className={classes.paperBody}>
        <Typography variant="h6" className={classes.productTitle}>{name}</Typography>
        <div className={classes.dataContainer}>
          <div className={classes.infoContainer}>
            <AssignmentIcon style={{ fontSize: 12 }} />
            <Typography variant="h6" className={classes.dataText}>
              {count}
              {' '}
              posts
            </Typography>
          </div>
        </div>
      </div>

    </Paper>
  );
};

export default ProductItem;

ProductItem.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  showProduct: PropTypes.func.isRequired,
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  layoutContainer: {
    display: 'flex',
    height: '100%',
  },
  componentContainer: {
    marginTop: '60px',
    position: 'static',
    padding: '50px 80px',
    marginLeft: '25%',
    width: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      padding: '50px 20px',
    },
  },
}));

export default useStyles;

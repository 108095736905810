export default {
  register: 'Register',
  formData: {
    name: 'Name',
    company: 'Company',
    email: 'Email',
    password: 'Password',
  },
  login: {
    question: 'Already have an account?',
    answer: 'Log in',
  },
};

import { storeUser, removeUser } from '../services/User';

export const actions = {
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER',
  SET_PRODUCT: 'SET_PRODUCT',
  REMOVE_PRODUCT: 'REMOVE_PRODUCT',
  DARK_THEME: 'DARK_THEME',
};

const Reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_USER:
      storeUser(action.payload);
      return {
        ...state,
        user: action.payload,
      };
    case actions.REMOVE_USER:
      removeUser();
      return {
        ...state,
        user: null,
      };
    case actions.SET_PRODUCT:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };
    case actions.REMOVE_PRODUCT:
      return {
        ...state,
        product: null,
      };
    case actions.DARK_THEME:
      return {
        ...state,
        darkTheme: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;

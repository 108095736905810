export default {
  snackbarMessages: {
    successLogIn: 'You are logged in',
    credentialsError: 'Invalid email or password',
    communication: 'Invalid server communication',
    serverError: 'Error with the server',
    expiredToken: 'Session expired',
    successRegistration: 'you are registered',
    registrationError: 'Error with account creation',
    emailTaken: 'Email is already taken',
    emailNotFound: 'Email is not registered',
    wrongCredentials: 'Incorrect email or password',
    productNotFound: "We couldn't find the product you are looking for",
    settingsUpdated: 'Settings updated successfully',
    settingsFail: 'Settings update failed',
    categories: {
      created: 'New category successfully created',
      updated: 'Category has been successfully updated',
      deleted: 'Category has been successfully deleted',
    },
    reachedProductsLimit: "You've reached your maximum limit of products",
    formError: 'There seems to be an error in the form',
    inactiveSubscription: 'Your subscription is not active',
  },
  validations: {
    email: 'Please enter a valid email address',
    presence: 'Cannot be blank',
    password: "Passwords don't match",
    length: 'Must have at least 6 characters',
    format: 'Must contain at least one uppercase letter, one number and one special character',
  },
  actions: {
    accept: 'Continue',
    cancel: 'Cancel',
    save: 'Save changes',
    search: 'Search',
    createPost: 'Create post',
    copyClipboard: 'Copied to clipboard',
    edit: 'Edit',
    delete: 'Delete',
    finish: 'Finish',
    paySubscription: 'Pay subscription',
  },
  nouns: {
    posts: 'Posts',
    dashboard: 'Dashboard',
    products: 'Products',
  },
  info: {
    createdBy: 'Created by',
  },
  unknownUser: 'Unknown user',
};

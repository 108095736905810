const constraints = (t) => ({
  name: {
    presence: {
      message: t('common:validations:presence'),
      allowEmpty: false,
    },
  },
  email: {
    presence: {
      message: t('common:validations:presence'),
      allowEmpty: false,
    },
    email: {
      message: t('common:validations:email'),
    },
  },
  password: {
    presence: {
      message: t('common:validations.presence'),
      allowEmpty: false,
    },
    length: {
      minimum: 6,
      message: t('common:validations.length'),
    },
    format: {
      pattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#@$!%*?&])[a-zA-Z\\d#@$!%*?&]+',
      message: t('common:validations.format'),
    },
  },
});

export default constraints;

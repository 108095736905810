export default {
  notFound: "You still haven't created any products",
  createButton: 'Create new product',
  title: 'My Products',
  steps: {
    info: 'Project info',
    widget: 'Widget',
  },
  nextBtn: 'Next',
  card: {
    newProduct: 'New product',
    editProduct: 'Edit product',
    deleteTitle: 'Delete product',
    deleteDescription: "You won't be able to access this product anymore. Do you wish to continue?",
    nameField: 'Product name',
    descriptionField: 'Product description',
    languageField: 'Language',
    labelField: 'Label',
    websiteField: 'Website',
    siteReady: 'Your new product is ready',
    uriAddress: 'URI address',
    uploadImg: 'Upload image',
  },
  created: {
    title: 'Your product was created!',
    question: 'What do you want to do next?',
    newProduct: 'Start new product',
    newPost: 'Create post',
  },
  views: {
    dashboard: 'Dashboard',
    posts: 'Posts',
    settings: 'General settings',
  },
  logoUpdated: 'Product logo updated',
  errorUpdating: 'Error updating product logo',
};

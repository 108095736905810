import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Paper, Typography, Box } from '@material-ui/core';
import Eye from '@material-ui/icons/Visibility';
import useStyles from './PostCardStyle';

const PostCard = ({
  id, title, blurb, views, creation, author, category, color, editPost, openViews
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['common, posts']);
  const userLang = navigator.language || navigator.userLanguage;
  const creationTmp = new Date(creation);
  const localeDate = creationTmp.toLocaleString(userLang, { dateStyle: 'full' });

  const openViewsDrawer = (e) => {
    e.stopPropagation();
    openViews({ id, title, blurb });
  }

  return (
    <Paper elevation={3} className={classes.paper} onClick={() => editPost(id)}>
      <Box className={classes.category} style={{ backgroundColor: color }}>
        <Typography
          align="center"
          className={classes.categoryLabel}
        >
          {category}
        </Typography>
      </Box>
      <Typography variant="h3">{title}</Typography>
      <Typography className={classes.body}>{blurb}</Typography>
      <div className={classes.bottomContainer}>
        <div
          className={classes.smallContainer}
          onClick={openViewsDrawer}
        >
          <Eye className={classes.icon} />
          <Typography className={classes.iconText}>{views}</Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            {t('posts:seenPost')}
          </Typography>
        </div>
        <Typography className={classes.subtitle} variant="subtitle1">
          {t('common:info.createdBy')}
          {' '}
          {author}
          {' '}
          -
          {' '}
          {localeDate}
        </Typography>
      </div>

    </Paper>
  );
};

export default PostCard;

PostCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  views: PropTypes.number.isRequired,
  creation: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  editPost: PropTypes.func.isRequired,
};

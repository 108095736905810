import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  Link,
} from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import CustomInput from '../../components/CustomInput';
import Footer from '../../components/Footer';
import Image from '../../../assets/password-planet.png';
import logo from '../../../assets/new-logo.png';
import useStyles from './ForgotPasswordStyle';
import API from '../../../config/api';
import validation from '../../../utils/validation';
import constraints from './constraints';
import isValid from '../../../utils/isValid';

// Destructuring
const { unregistered } = API;

const ForgotPassword = () => {
  const classes = useStyles();
  const { lang } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['recoverPassword', 'common']);
  const [email, setEmail] = useState('');

  const [errorsData, setErrorsData] = useState({
    email: null,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  // eslint-disable-next-line

  const handleChange = (event) => {
    const result = validation('email', event.target.value, constraints(t));

    setErrorsData({ email: result });

    setEmail(event.target.value);
  };

  const forgotPassword = async (token) => {
    try {
      await unregistered.recoverPassword(email, token);
      enqueueSnackbar(
        t('recoverPassword:forgotPassword.emailSent'),
        { variant: 'success' },
      );
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages.emailNotFound'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages.serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const verifyRecaptcha = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
    }
    const token = await executeRecaptcha('ForgotPassword');
    forgotPassword(token);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <img className={classes.titleLogo} src={logo} alt="" />
          <Divider style={{ margin: '0 25px' }} orientation="vertical" flexItem />
          <Typography className={classes.titleTypography} variant="h2">
            {t('recoverPassword:recoverTitle')}
          </Typography>
        </div>
        <Paper elevation={3} className={classes.paper}>
          <Grid container spacing={7}>
            <Grid className={classes.gridItem} item xs={12} sm={6}>
              <form className={classes.form}>
                <CustomInput
                  label="Email"
                  value={email}
                  handleChange={handleChange}
                  marginValues="0 0 35px 0"
                  errorMessage={errorsData.email}
                />
                <Button
                  type="submit"
                  disableElevation
                  disabled={!isValid({ email }, constraints, t)}
                  style={{ width: '177px' }}
                  variant="contained"
                  color="primary"
                  onClick={verifyRecaptcha}
                >
                  {t('recoverPassword:actions.recover')}
                </Button>
              </form>
              <div className={classes.actionsContainer}>
                <div className={classes.lineContainer}>
                  <Typography className={classes.actionsQuestion}>
                    {t('recoverPassword:actions.question')}
                  </Typography>
                  <Link component={RouterLink} to={`/${lang}/login`}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {t('recoverPassword:actions.answer')}
                    </Typography>
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid
              className={classes.gridItem}
              style={{ alignItems: 'center' }}
              item
              xs={12}
              sm={6}
            >
              <img src={Image} className={classes.image} alt="" />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;

// React
import React from 'react';

// React router
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

// Custom Components
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { SnackbarProvider } from 'notistack';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// Screens
import LogIn from '../app/screens/LogIn';
import Register from '../app/screens/Register';
import Products from '../app/screens/Products';
import ProductInfo from '../app/screens/ProductInfo';
import PublicPosts from '../app/screens/PublicPosts';
import ForgotPassword from '../app/screens/ForgotPassword';
import ResetPassword from '../app/screens/ResetPassword';

// Styles
import theme from '../theme';

// Services
import AppContextProvieder from '../context/AppContextProvieder';

const App = () => {
  const lang = window.location.pathname.slice(1, 3);
  // const newPath = window.location.pathname.slice(3);
  let locale = enLocale;

  switch (lang) {
    case 'es':
      locale = esLocale;
      break;
    case 'en':
      locale = enLocale;
      break;
    case '':
      window.location.assign(`/en${window.location.pathname}`);
      locale = enLocale;
      break;
    default:
      window.location.assign(`/en${window.location.pathname}`);
      locale = enLocale;
      break;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      useEnterprise={false}
    >
      <AppContextProvieder>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Router>
                <Switch>
                  <Redirect exact from="/:lang/" to="/:lang/login" />
                  <Redirect exact from="/:uri" to="/en/:uri" />
                  <Redirect exact from="/reset-password/:token" to="/en/reset-password/:token" />
                  <PrivateRoute component={Products} path="/:lang/products" exact />
                  <PrivateRoute component={ProductInfo} path="/:lang/products/:uri/:comp" exact />
                  <PublicRoute component={LogIn} restricted={false} path="/:lang/login" exact />
                  <PublicRoute component={Register} restricted={false} path="/:lang/register" exact />
                  <PublicRoute component={ForgotPassword} restricted={false} path="/:lang/forgot-password" exact />
                  <PublicRoute component={ResetPassword} restricted={false} path="/:lang/reset-password/:token" exact />
                  <Route path="/:lang/:uri">
                    <PublicPosts />
                  </Route>
                  <Redirect exact from="/:lang/products/:uri" to="/:lang/products/:uri/dashboard" />
                </Switch>
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AppContextProvieder>
    </GoogleReCaptchaProvider>
  );
};

export default App;

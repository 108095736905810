export default {
  create: 'Create category',
  columns: {
    name: 'Name',
    color: 'Background color',
  },
  edit: 'Edit category',
  deleteTitle: 'Delete category',
  deleteDescription: 'Do you want to delete this category?',
  usedIn: 'Category used in',
};

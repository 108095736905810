import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './CategoryCardStyle';

const CategoryCard = ({ title, color }) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.paper}
      elevation={3}
      style={{ borderLeft: `8px solid ${color}` }}
    >
      <Typography className={classes.title}>{title}</Typography>
    </Paper>
  );
};

export default CategoryCard;

CategoryCard.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  keymap,
  highlightSpecialChars,
  drawSelection,
  highlightActiveLine,
  dropCursor,
  rectangularSelection,
  crosshairCursor,
  lineNumbers,
  highlightActiveLineGutter,
  EditorView,
} from '@codemirror/view';
import { EditorState } from '@codemirror/state';
import {
  defaultHighlightStyle,
  syntaxHighlighting,
  indentOnInput,
  bracketMatching,
  foldGutter,
  foldKeymap,
} from '@codemirror/language';
import { defaultKeymap, history, historyKeymap } from '@codemirror/commands';
import { searchKeymap, highlightSelectionMatches } from '@codemirror/search';
import {
  autocompletion,
  completionKeymap,
  closeBrackets,
  closeBracketsKeymap,
} from '@codemirror/autocomplete';
import { lintKeymap } from '@codemirror/lint';

// (The superfluous function calls around the list of extensions work
// around current limitations in tree-shaking software.)
const extensions = (() => [
  lineNumbers(),
  highlightActiveLineGutter(),
  highlightSpecialChars(),
  history(),
  foldGutter(),
  drawSelection(),
  dropCursor(),
  EditorState.allowMultipleSelections.of(true),
  indentOnInput(),
  syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
  bracketMatching(),
  closeBrackets(),
  autocompletion(),
  rectangularSelection(),
  crosshairCursor(),
  highlightActiveLine(),
  highlightSelectionMatches(),
  keymap.of([
    ...closeBracketsKeymap,
    ...defaultKeymap,
    ...searchKeymap,
    ...historyKeymap,
    ...foldKeymap,
    ...completionKeymap,
    ...lintKeymap,
  ]),
])();

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    overflowY: 'scroll',
    '& .cm-editor': {
      height: '100%',
      border: '1px solid lightgray',
    },
  },
}));

const CodemirrorEditor = ({ defaultValue, langExtension, viewRef }) => {
  const classes = useStyles();
  const rootRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    viewRef.current = new EditorView({
      doc: defaultValue,
      extensions: [extensions, langExtension()],
      parent: rootRef.current,
    });

    return () => {
      viewRef.current.destroy();
    };
  }, [defaultValue, langExtension, viewRef]);

  return <div className={classes.root} ref={rootRef} />;
};

CodemirrorEditor.propTypes = {
  langExtension: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  viewRef: PropTypes.shape({
    current: PropTypes.instanceOf(EditorView),
  }).isRequired,
};

CodemirrorEditor.defaultProps = {
  defaultValue: '',
};

export default CodemirrorEditor;

export default {
  register: {
    question: "Don't have an account yet?",
    answer: 'Create one here',
  },
  forgotPass: {
    question: 'Forgot your password?',
    answer: 'Click here',
  },
  formData: {
    user: 'Username',
    password: 'Password',
  },
  login: 'Log in',
};

import login from './login';
import register from './register';
import account from './account';
import brand from './brand';
import categories from './categories';
import common from './common';
import dashboard from './dashboard';
import months from './months';
import navbar from './navbar';
import posts from './posts';
import settings from './settings';
import sidebar from './sidebar';
import product from './product';
import widget from './widget';
import recoverPassword from './recoverPassword';

export default {
  login,
  register,
  account,
  brand,
  categories,
  common,
  dashboard,
  months,
  navbar,
  posts,
  product,
  settings,
  sidebar,
  widget,
  recoverPassword,
};

const constraints = (t) => {
  return {
    name: {
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      },
    },
    email: {
      email: {
        message: t('common:validations:email'),
      },
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      },
    },
  }
};

export default constraints;

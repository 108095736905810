import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loadingRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  backdrop: {
    color: 'rgb(255,255,255)',
    zIndex: 2,
  },
  icon: {
    fontSize: '1.3rem',
    color: '#4E6678',
  },
  paperContainer: {
    width: '700px',
    padding: '35px 55px',
    backgroundColor: '#F5F7F8',
    height: '100%',
  },
  paperCard: {
    height: '90%',
    padding: '35px 55px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editButton: {
    width: '177px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '15px',
      width: '100%',
    },
  },
  closeIcon: {
    color: '#55CC7F',
    position: 'absolute',
    right: '70px',
    top: '45px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  datePicker: {
    margin: '0 16px 0 0',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginTop: '15px',
    },
  },
  dateButton: {
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '15px',
    },
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  dateContainer: {
    display: 'flex',
    marginTop: '35px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      marginTop: '25px',
    },
  },
  bubbleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '35px 0',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      margin: '25px 0',
    },
  },

}));

export default useStyles;

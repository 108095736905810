export default {
  form: {
    title: 'Título',
    font: 'Fuente',
  },
  color: {
    scheme: 'Color',
    primary: 'Color primario',
  },
  styling: 'Estilos',
  styleInstructions: 'Personaliza tu widget de acuerdo a tus necesidades.',
  save: 'Guardar cambios',
  darkTheme: 'Tema oscuro',
  preview: 'Vista previa',
};

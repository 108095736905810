import validate from 'validate.js';

const validation = (field, value, constraints) => {
  const formValues = { [field]: value };
  const constraint = { [field]: constraints[field] };
  const result = validate(formValues, constraint, { fullMessages: false });

  if (result) {
    return result[field][0];
  }
  return null;
};

export default validation;

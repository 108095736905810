import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useStyles from './ProductInfoStyle';
import Navbar from '../../components/Navbar';
import Dashboard from '../../components/Dashboard';
import Bar from '../../components/Bar';
import Posts from '../../components/Posts';
import GeneralSettings from '../../components/GeneralSettings';
import CustomCircularProgress from '../../components/CustomCircularProgress';
import { Context } from '../../../context/AppContextProvieder';
import { actions } from '../../../context/Reducer';
import API from '../../../config/api';

const ProductInfo = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'sidebar']);
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(true);
  const [component, setComponent] = useState(
    <Dashboard />,
  );
  const { product } = API;
  const { uri, comp } = useParams();

  const fetchProductByURI = async () => {
    try {
      const { data } = await product.showProductByURI(uri, state.user.token);
      dispatch({ type: actions.SET_PRODUCT, payload: data.data });
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:productNotFound'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
    setLoader(false);
  };

  const loadPage = () => {
    switch (comp) {
      case 'posts':
        setComponent(<Posts />);
        setPage(1);
        break;
      case 'settings':
        setComponent(<GeneralSettings />);
        setPage(2);
        break;
      default:
        setComponent(<Dashboard />);
        setPage(0);
        break;
    }
  };

  const fetchNewProduct = () => {
    fetchProductByURI()
      .then(() => {
        loadPage();
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!state.product) {
      fetchNewProduct();
    } else if (uri !== state.product.attributes.product_uri) {
      fetchNewProduct();
    } else {
      loadPage();
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [uri, comp])

  useEffect(() => () => {
    dispatch({ type: actions.REMOVE_PRODUCT });
  }, []);

  if (loader) {
    return (
      <CustomCircularProgress />
    );
  }
  return (
    <div className={classes.root}>
      <Bar name={state.user.name} siteSelected />
      <div className={classes.layoutContainer}>
        <Navbar
          currPage={page}
        />
        <div className={classes.componentContainer}>

          {component && (component)}
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;

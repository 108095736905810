import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: '35px',
    backgroundColor: '#F5F7F8',
    flex: 1,
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '700px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '25px',
    },
  },
  paper: {
    // width: '700px',
    // padding: '35px 55px',
    // marginBottom: '35px',
    // flex: 1,
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    padding: '35px 45px',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '25px 35px',
    },

  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  descriptionTitle: {
    color: '#5E7B91',
    marginTop: '35px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  datePicker: {
    margin: '45px 0 0 0',
    width: '100%',
  },
  quill: {
    height: '250px',
  },
  separatorSpan: {
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  categoryContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    transform: 'translateX(-10px)',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '170px',
    marginLeft: '5px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
      width: '100%',
      marginTop: '15px',
    },
  },
  deleteBtn: {
    width: '170px',
    marginLeft: '5px',
    color: 'red',
    borderColor: 'red',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
      width: '100%',
      marginTop: '15px',
    },
  },
  operationsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editorContainer: {
    padding: '10px 50px',
  },
}));

export default useStyles;

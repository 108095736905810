import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
    minWidth: '400px',
    maxWidth: '600px',
    padding: '20px',
    borderRadius: '10px',
    outline: 'none',
  },
  bodyTypography: {
    margin: '20px 0px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '150px',
  },
}));

export default useStyles;

import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Paper, Typography, Box } from '@material-ui/core';
import useStyles from './PublishedPostStyle';
import blockToCode from '../../../utils/blockToCode';

const PublishedPost = ({
  title, creation, category, color, delta, darkTheme,
}) => {
  const classes = useStyles();
  const creationTmp = new Date(creation);
  const { lang } = useParams();
  const localeDate = creationTmp.toLocaleString(lang, { dateStyle: 'full' });

  return (
    <Paper
      elevation={3}
      className={classes.paper}
      style={darkTheme ? { backgroundColor: '#333333' } : null}
    >
      <div style={{ display: 'flex' }}>
        <Box className={classes.category} style={{ backgroundColor: color }}>
          <Typography align="center" className={classes.categoryLabel}>{category}</Typography>
        </Box>
        <Typography className={classes.subtitle} variant="subtitle1">{localeDate}</Typography>
      </div>

      <Typography variant="h3">{title}</Typography>
      <div style={{ color: darkTheme ? 'white' : '#4E6678' }}>
        {
          delta.blocks.map((block) => blockToCode({ block }))
        }
      </div>
    </Paper>
  );
};

export default PublishedPost;

PublishedPost.propTypes = {
  title: PropTypes.string,
  creation: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  darkTheme: PropTypes.bool,
};

PublishedPost.defaultProps = {
  title: '',
  darkTheme: false,
};

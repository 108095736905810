import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import useStyles from './AccountStyle';
import AccountInfo from '../AccountInfo';
import AccountSecurity from '../AccountSecurity';
// import AccountSubscription from '../AccountSubscription';
// import AccountInvoice from '../AccountInvoice';

const Account = ({ setOpen }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation(['account']);
  const changeTab = (event, tab) => {
    setTab(tab);
  };

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h2">{t('title')}</Typography>
        <Tabs
          className={classes.tabs}
          value={tab}
          variant={windowWidth <= 600 && 'fullWidth'}
          onChange={changeTab}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label={t('tabs.info')} />
          <Tab label={t('tabs.security')} />
          {/* <Tab label={t('account:tabs.subscription')} />
          <Tab label={t('account:tabs.invoice')} /> */}
        </Tabs>
        <div className={classes.panelContainer}>
          {tab === 0 ? (
            <AccountInfo setOpen={setOpen} />
          ) : (
            <AccountSecurity setOpen={setOpen} />
          )}
          {/* <TabPanel value={tab} index={2}>
            <AccountSubscription />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <AccountInvoice />
          </TabPanel> */}
        </div>
      </Paper>
    </div>
  );
};

export default Account;

Account.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

export default {
  register: {
    question: '¿No tienes una cuenta?',
    answer: 'Crea una',
  },
  forgotPass: {
    question: '¿Olvidaste tu contraseña?',
    answer: 'Haz click aqui',
  },
  formData: {
    user: 'Usuario',
    password: 'Contraseña',
  },
  login: 'Ingresar',
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import es from './locales/es';

// import LanguageDetector from 'i18next-browser-languagedetector';

const lang = window.location.pathname.slice(1, 3);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      es,
    },
    debug: false,

    lng: lang,
    fallbackLng: 'en',
    whitelist: ['en', 'es'],
    // initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React from 'react';
import { Typography, Button, Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './ConfirmModalStyle';

const ConfirmModal = ({
  title, body, acceptFunc, open, onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
    >
      <div className={classes.container}>
        <Typography variant="h3">{title}</Typography>
        <Typography className={classes.bodyTypography} variant="body1">{body}</Typography>
        <div className={classes.btnContainer}>
          <Button
            onClick={onClose}
            className={classes.button}
          >
            {t('actions.cancel')}
          </Button>
          <div style={{ width: '30px' }} />
          <Button
            color="primary"
            variant="contained"
            onClick={acceptFunc}
            className={classes.button}
          >
            {t('actions.accept')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

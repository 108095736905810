import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import Password from '../Password';
import useStyles from './AccountSecurityStyle';
import { Context } from '../../../context/AppContextProvieder';
import API from '../../../config/api';
import isValid from '../../../utils/isValid';
import validation from '../../../utils/validation';
import constraints from './constraints';

const AccountSecurity = ({ setOpen }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['account', 'common']);
  const { user } = API;
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const [formData, setFormData] = useState({
    currPassword: '',
    newPassword: '',
    confPassword: '',
  });
  const [errorsData, setErrorsData] = useState({
    password: null,
    newPassword: null,
  });

  const updatePassword = async () => {
    try {
      await user.updatePassword(state.user.user.id, {
        password: formData.currPassword,
        new_password: formData.newPassword,
        new_password_confirmation: formData.newPassword,
      }, state.user.token);
      enqueueSnackbar(t('account:security.success'), { variant: 'success' });
      setOpen(false);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const handleChange = (e) => {
    const result = validation(e.target.name, e.target.value, constraints(t));

    setErrorsData({
      ...errorsData,
      [e.target.name]: result,
    });

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={classes.root}>
      <Password
        name="currPassword"
        value={formData.currPassword}
        handleChange={handleChange}
        marginValues="0 0 0 0"
        label={t('account:security.currPassword')}
        errorMessage={errorsData.currPassword}
      />
      <Password
        name="newPassword"
        value={formData.newPassword}
        handleChange={handleChange}
        marginValues="35px 0 0 0"
        label={t('account:security.newPassword')}
        errorMessage={errorsData.newPassword}
      />
      <div className={classes.buttonContainer}>
        <Button
          style={{ color: '#4E6678' }}
          className={classes.btn}
          onClick={() => setOpen(false)}
        >
          {t('common:actions.cancel')}

        </Button>
        <Button
          disabled={!isValid(formData, constraints, t)}
          onClick={updatePassword}
          color="primary"
          variant="contained"
          className={classes.btn}
        >
          {t('common:actions.save')}
        </Button>
      </div>
    </div>
  );
};

export default AccountSecurity;

AccountSecurity.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

import React, { useState, useContext } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  Link,
} from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Password from '../../components/Password';
import CustomInput from '../../components/CustomInput';
import Footer from '../../components/Footer';
import Image from '../../../assets/login-planet.png';
import logo from '../../../assets/new-logo.png';
import useStyles from './LogInStyle';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import { actions } from '../../../context/Reducer';
import validation from '../../../utils/validation';
import constraints from './constraints';
import isValid from '../../../utils/isValid';
import { Mixpanel } from '../../../utils/Mixpanel';

// Destructuring
const { user } = API;

const LogIn = () => {
  const classes = useStyles();
  const history = useHistory();
  const { lang } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('login');
  const [formData, setFormData] = useState({
    user: '',
    password: '',
  });
  const [errorsData, setErrorsData] = useState({
    user: null,
    password: null,
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);

  const handleChange = (event) => {
    const result = validation(event.target.name, event.target.value, constraints(t));

    setErrorsData({
      ...errorsData,
      [event.target.name]: result,
    });

    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const logIn = async (token) => {
    try {
      const { data } = await user.logIn(formData.user, formData.password, false, token);
      dispatch({ type: actions.SET_USER, payload: data });
      enqueueSnackbar(
        t('common:snackbarMessages:successLogIn'),
        { variant: 'success' },
      );
      Mixpanel.track('SignIn');
      history.push(`/${lang}/products`);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 401) {
        enqueueSnackbar(
          t('common:snackbarMessages:wrongCredentials'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const verifyRecaptcha = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
    }
    const token = await executeRecaptcha('SignIn');
    logIn(token);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <img className={classes.titleLogo} src={logo} alt="" />
          <Divider style={{ margin: '0 25px' }} orientation="vertical" flexItem />
          <Typography className={classes.titleTypography} variant="h2">{t('login:login')}</Typography>
        </div>
        <Paper elevation={3} className={classes.paper}>
          <Grid container spacing={7}>
            <Grid className={classes.gridItem} item sm={12} md={6}>
              <form className={classes.form}>
                <CustomInput
                  name="user"
                  label={t('login:formData.user')}
                  value={formData.user}
                  handleChange={handleChange}
                  marginValues="0 0 35px 0"
                  errorMessage={errorsData.user}
                />
                <Password
                  name="password"
                  label={t('login:formData.password')}
                  value={formData.password}
                  handleChange={handleChange}
                  errorMessage={errorsData.password}
                />
                <Button
                  type="submit"
                  disableElevation
                  disabled={!isValid(formData, constraints, t)}
                  style={{ marginTop: '30px', width: '177px' }}
                  variant="contained"
                  color="primary"
                  onClick={verifyRecaptcha}
                >
                  {t('login:login')}
                </Button>
              </form>
              <div className={classes.actionsContainer}>
                <Typography className={classes.actionsQuestion}>
                  {t('login:register.question')}
                </Typography>
                <Link component={RouterLink} to={`/${lang}/register`}>
                  <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>
                    {t('login:register.answer')}
                  </Typography>
                </Link>
                <Typography className={classes.actionsQuestion}>
                  {t('login:forgotPass.question')}
                </Typography>
                <Link component={RouterLink} to={`/${lang}/forgot-password`}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {t('login:forgotPass.answer')}
                  </Typography>
                </Link>
              </div>
            </Grid>
            <Grid
              className={classes.gridItem}
              style={{ alignItems: 'center' }}
              item
              sm={12}
              md={6}
            >
              <img src={Image} className={classes.image} alt="" />
            </Grid>
          </Grid>
        </Paper>
        <Typography className={classes.recaptchaText}>
          This site is protected by reCAPTCHA and the Google
          {' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a>
          {' '}
          and
          {' '}
          <a href="https://policies.google.com/terms">Terms of Service</a>
          {' '}
          apply.
        </Typography>
      </div>
      <Footer />
    </div>
  );
};

export default LogIn;

export default {
  notFound: "Aun no ha creado ningun producto",
  createButton: 'Crear nuevo producto',
  title: 'Mis productos',
  steps: {
    info: 'Información del producto',
    widget: 'Widget',
  },
  nextBtn: 'Siguiente',
  card: {
    newProduct: 'Nuevo producto',
    editProduct: 'Editar producto',
    deleteTitle: 'Borrar producto',
    deleteDescription: 'No podrá volver a acceder a este producto. ¿Desea continuar?',
    nameField: 'Nombre del producto',
    descriptionField: 'Descripción del producto',
    languageField: 'Idioma',
    labelField: 'Etiqueta',
    websiteField: 'Sitio web',
    siteReady: 'Su nuevo producto esta listo',
    uriAddress: 'Dirección URI',
    uploadImg: 'Subir imagen',
  },
  created: {
    title: '¡Su producto ha sido creado!',
    question: '¿Que quieres hacer ahora?',
    newProduct: 'Iniciar nuevo producto',
    newPost: 'Crear publicación',
  },
  views: {
    dashboard: 'Dashboard',
    posts: 'Publicaciones',
    settings: 'Configuración general',
  },
  logoUpdated: 'Logo actualizado',
  errorUpdating: 'Error actualizando el logo',
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: '35px',
    backgroundColor: '#F5F7F8',
    flex: 1,
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '700px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '25px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '35px 45px',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '25px 35px',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bubbleContainer: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  rightBtnCont: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },
  button: {
    width: '177px',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '10px',
      marginLeft: 0,
    },
  },
  delButton: {
    width: '177px',
    color: 'red',
    borderColor: 'red',
    alignSelf: 'flex-start',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '35px',
    backgroundColor: '#F5F7F8',
    flex: 1,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      padding: '25px',
    },
  },
  paper: {
    padding: '35px 45px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: '25px 35px',
    },
  },
  tabs: {
    marginTop: '25px',
  },
  panelContainer: {
    marginTop: '55px',
    display: 'flex',
    flex: 1,
  },
}));

export default useStyles;

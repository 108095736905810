export default {
  title: 'My account',
  tabs: {
    info: 'General information',
    security: 'Security',
    subscription: 'Subscription',
    invoice: 'Invoice',
  },
  userInfo: {
    name: 'Name',
    email: 'Email',
    company: 'Company',
    success: 'Your information has been updated',
  },
  security: {
    currPassword: 'Current password',
    newPassword: 'New password',
    confPassword: 'Confirm password',
    success: 'Your password has been updated',
  },
};

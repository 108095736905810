const mapPosts = (postsData) => {
  const array = postsData.data.map((post) => {
    const categoryId = post.relationships.category.data.id;
    const categoryData = postsData.included.filter((category) => (categoryId === category.id));
    return ({
      id: post.id,
      title: post.attributes.title,
      blurb: post.attributes.blurb,
      views: post.attributes.view_counter,
      creation: post.attributes.created_at,
      author: post.attributes.created_by_name,
      category: categoryData[0].attributes.name,
      color: categoryData[0].attributes.background_color,
      draft: post.attributes.title,
    });
  });
  return array;
};

export default mapPosts;

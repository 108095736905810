import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loadingRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '35px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: '25px',
    },
  },
  drawer: {
    // padding: '35px',
    // paddingBottom: '0px',
    // backgroundColor: '#F5F7F8',
    // display: 'flex',
    // flex: 1,
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  createButton: {
    width: '170px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '15px',
    },
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
  },
  iconsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '25px',
  },
  icon: {
    marginLeft: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '70px',
  },
  notFoundText: {
    width: '50%',
    marginTop: '20px',
    color: '#161C22AF',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;

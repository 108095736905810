export default {
  notFound: {
    partOne: 'Comienza por hacer click en ',
    partTwo: ', una vez se haya publicado, se mostrará aqui y podrás llevar un registro de las vistas de tus usuarios.',
  },
  public: {
    noPosts: 'No se encontraron publicaciones',
  },
  tabs: {
    published: 'Publicaciones',
    drafts: 'Borradores',
  },
  created: 'Se ha creado una nueva publicación',
  deleted: 'La publicación ha sido eliminada',
  cardTitles: {
    createTitle: 'Crear publicación',
    editTitle: 'Editor publicación',
    defaultPostBody: 'Escriba aquí la nueva característica de su producto...',
    deleteTitle: 'Borrar publicación',
  },
  messages: {
    confirmDelete: '¿Esta seguro que desea eliminar esta publicación?',
  },
  new: {
    title: 'Crear publicación',
    form: {
      title: 'Título de la publicación',
      category: 'Categoría',
      date: 'Fecha de publicación',
      description: 'Descripción',
    },
    draft: 'Marcar como borrador',
  },
  viewsDrawer: {
    date: 'Fecha',
    user: 'Usuario',
    countryCode: 'Código del pais',
    noViews: 'Esta publicación no tiene vistas',
  },
  seenPost: '¿Quién ha visto mi publicación?',
};

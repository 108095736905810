// React
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

// Services
import Reducer from './Reducer';
import { getUser } from '../services/User';

const initialState = {
  user: getUser(),
};

const AppContextProvieder = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  );
};

AppContextProvieder.propTypes = {
  children: PropTypes.element.isRequired,
};

export const Context = createContext(initialState);
export default AppContextProvieder;

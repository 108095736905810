import React, { useRef, useContext } from 'react';
import { Context } from '../../../context/AppContextProvieder';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { css as langCss } from '@codemirror/lang-css';
import CodemirrorEditor from '../CodeMirrorEditor/CodeMirrorEditor';
import api from '../../../config/api';
import { useSnackbar } from 'notistack';
import createWidgetScript from '../../../utils/createWidgetScript';
import { useTranslation } from 'react-i18next';
import { actions } from '../../../context/Reducer';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 24,
    height: window.screen.height * 0.3,
  },
  title: {
    marginBottom: 24
  },
  btnContainer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const WidgetStyling = ({widgetParams, setWidgetParams}) => {
  const [state, dispatch] = useContext(Context);
  const viewRef = useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation(['common', 'brand'])

  const saveChanges = async () => {
    try {
      const customCss = viewRef.current?.state?.doc?.toString() || null;
      if (customCss) {
        const {data} = await api.product.updateWidget({
          id: state.product.id,
          widget: {
            title: widgetParams.title,
            color: widgetParams.color,
            dark_mode: widgetParams.dark_mode,
            custom_css: customCss
          },
          authToken: state.user.token,
        });
        dispatch({type: actions.SET_PRODUCT, payload: data.data});
        setWidgetParams((params) => ({
          ...params,
          custom_css: customCss
        }));
        createWidgetScript();
        enqueueSnackbar(
          t('common:snackbarMessages.settingsUpdated'),
          { variant: 'success' },
        );
      }
    } catch (error) {
      enqueueSnackbar(
        t('common:snackbarMessages.settingsFail'),
        { variant: 'warning' },
      );
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="h2">
          {t('brand:styling')}
        </Typography>
      </div>
      <Typography style={{ marginBottom: 18 }}>
        {t('brand:styleInstructions')}
      </Typography>
      <CodemirrorEditor
        langExtension={langCss}
        viewRef={viewRef}
        defaultValue={widgetParams.custom_css}
      />
      <div className={classes.btnContainer}>
        <Button
          onClick={saveChanges}
          variant="contained"
          color="primary"
        >
          {t('common:actions.save')}
        </Button>
      </div>
    </div>
  );
};

export default WidgetStyling;

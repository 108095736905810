import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem, FormHelperText,
} from '@material-ui/core';

const CustomSelect = ({
  label, options, value, name, marginValues, handleChange, errorMessage,
}) => (
  <FormControl style={{ margin: marginValues }} fullWidth variant="outlined">
    <InputLabel shrink={false}>{label}</InputLabel>
    <Select name={name} value={value} onChange={handleChange}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
      ))}
    </Select>
    {
      errorMessage
      && (
        <FormHelperText
          error
        >
          {errorMessage}
        </FormHelperText>
      )
    }
  </FormControl>
);

export default CustomSelect;

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  marginValues: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ index, children, value }) => (
  <div>
    {value === index && (
      children
    )}
  </div>
);

export default TabPanel;

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  darkDrawer: {
    backgroundColor: '#333333',
  },
  light: {
    padding: '0px 20px',
    width: '400px',
    backgroundColor: '#EDF0F2',
    height: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  dark: {
    backgroundColor: '#333333',
    padding: '0px 20px',
    width: '400px',
    height: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    zIndex: '1',
    boxShadow: '0px 1px 8px 0px rgba(0,0,0,0.5)',
  },
  arrowBack: {
    color: 'white',
    fontSize: '14px',
    position: 'absolute',
    right: '395px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleLight: {
    color: 'white',
  },
  titleDark: {
    color: '#D9E1E7',
    backgroundColor: '#2892EE',
  },
  closeIcon: {
    color: 'white',
    position: 'absolute',
    right: '20px',
    top: '15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;

export default {
  register: 'Registro',
  formData: {
    name: 'Nombre',
    company: 'Compañía',
    email: 'Email',
    password: 'Contraseña',
  },
  login: {
    question: '¿Ya tienes una cuenta?',
    answer: 'Ingresa',
  },
};

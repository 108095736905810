import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '30px',
    marginBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  category: {
    padding: '5px 10px',
    display: 'inline-block',
    borderRadius: '10px',
    minWidth: '65px',
    marginBottom: '20px',
  },
  categoryLabel: {
    color: 'white',
    fontWeight: 700,
    fontSize: '0.7rem',
  },
  body: {
    marginBottom: '20px',
    marginTop: '10px',
    color: '#4E6678',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  smallContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px'
    }
  },
  icon: {
    marginRight: '10px',
    color: '#4E6678',
    fontSize: '1rem',
  },
  iconText: {
    color: '#4E6678',
    fontSize: '1rem',
    fontWeight: 700,
    marginRight: '10px',
  },
  subtitle: {
    color: '#BABFC4',
  },
}));

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bubblesContainer: {
    // display: 'flex',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  colorTitleContainer: {
    marginTop: '30px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  colorTitle: {
    fontSize: '1rem',
    color: '#7B747B',
    marginRight: '10px',
  },
  switchContainer: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
  },
  previewContainer: {
    display: 'flex',
    marginTop: '25px',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  helpIcon: {
    marginLeft: '5px',
    color: '#575757',
    fontSize: '1rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    marginTop: '35px',
    float: 'right',
    width: '177px',
  },
}));

export default useStyles;

import React, { useEffect, useState, useContext } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './UserViewsStyles';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import NoPostsImg from '../../../assets/Illustration_Notfound.svg';

const mapViewsData = (views, t) => {
  const userLang = navigator.language || navigator.userLanguage;
  return views.map((view) => {
    const date = new Date(view.attributes.created_at);
    const localeDate = date.toLocaleString(userLang, { dateStyle: 'medium' });
    return ({
      id: views.id,
      user: view.attributes.user_metadata || t('common:unknownUser'),
      date: localeDate,
    })
  })
}

const UserViews = ({ open, closeDrawer, post }) => {
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [viewsData, setViewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'posts']);
  const { product } = API;

  const changePage = (e, page) => {
    setPage(page);
  };

  const fetchProductViews = async () => {
    try {
      const { data } = await product.productViews({
        postId: post.id,
        page,
        perPage: 10,
        authToken: state.user.token,
      });
      const views = mapViewsData(data.data, t);
      setViewsData([...views]);
      setTotalPages(data.meta.total_pages);
      setIsLoading(false);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      fetchProductViews();
    } else {
      setPage(1);
    }
  }, [page, open])

  return (
    <Drawer
      classes={{
        paper: classes.drawer
      }}
      anchor='right'
      open={open}
      onClose={closeDrawer}
    >
      <div className={classes.container}>
        <Paper className={classes.paper}>
          {isLoading ? (
            <div className={classes.loaderContainer}>
              <CircularProgress style={{ alignSelf: 'center' }} />
            </div>
          ) : (
            <>
              <div className={classes.titleContainer}>
                <Typography variant='h2'>{post.title || ''}</Typography>
                <CloseIcon
                  className={classes.closeIcon}
                  color="primary"
                  onClick={closeDrawer}
                />
              </div>
              <Typography className={classes.blurb}>{post.blurb || ''}</Typography>
              {viewsData.length === 0 ? (
                <div className={classes.noViewscont}>
                  <img src={NoPostsImg} alt="Missing" width={250} />
                  <Typography className={classes.noViewsText}>
                    {t('posts:viewsDrawer.noViews')}
                  </Typography>
                </div>
              ) : (
                <>
                  <TableContainer
                    className={classes.tableContainer}
                    component={Paper}
                  >
                    <Table size='small'>
                      <TableHead>
                        <TableRow
                          className={classes.tableHeader}
                        >
                          <TableCell className={classes.tableCell}>
                            {t('posts:viewsDrawer.date')}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {t('posts:viewsDrawer.user')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewsData.map((view) => (
                          <TableRow key={view.id}>
                            <TableCell className={classes.tableCell}>
                              {view.date}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {view.user}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className={classes.paginationCont}>
                    <Pagination
                      page={page}
                      color="primary"
                      count={totalPages}
                      onChange={changePage}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </Paper>
      </div>
    </Drawer>
  )
};

export default UserViews;

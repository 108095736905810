import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  title: {
    fontSize: 12.8,
    color: '#4E6678',
  },
}));

export default useStyles;

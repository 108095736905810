import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const CustomCircularProgress = () => (
  <div style={{
    display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', height: '100%',
  }}
  >
    <CircularProgress />
  </div>
);

export default CustomCircularProgress;

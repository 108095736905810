import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    zIndex: 1,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000614',
    height: '40px',
    width: '100%',
  },
  container: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '98%',
    },
  },
  icon: {
    margin: '0 10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;

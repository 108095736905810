export default {
  snackbarMessages: {
    successLogIn: 'Autenticación exitosa',
    credentialsError: 'Correo o contraseña inválido',
    communication: 'Error de comunicación con el servidor',
    serverError: 'Error con el servidor',
    expiredToken: 'Sesión expirada',
    successRegistration: 'Registro exitoso',
    registrationError: 'Error al crear la cuenta',
    emailTaken: 'El correo ha sido tomado',
    emailNotFound: 'El correo ingresado no esta registrado',
    wrongCredentials: 'Correo o contraseña inválido',
    productNotFound: 'No pudimos encontrar el producto',
    settingsUpdated: 'Configuración modificada exitosamente',
    settingsFail: 'Configuración no modificada',
    categories: {
      created: 'Categoría creada exitosamente',
      updated: 'Categoría modificada exitosamente',
      deleted: 'La categoria ha sido eliminada',
    },
    reachedProductsLimit: "Ha alcanzado su máximo número de productos",
    formError: 'Error en el formulario',
    inactiveSubscription: 'Su suscriptión no esta activa',
  },
  validations: {
    email: 'No parece ser un correo válido',
    presence: 'Debe llenar este campo',
    password: 'Las contraseñas no coinciden',
    length: 'Debe tener por lo menos 6 caracteres',
    format: 'Debe contener al menos una mayúscula, un número y un caracter especial',
  },
  actions: {
    accept: 'Aceptar',
    cancel: 'Cancelar',
    save: 'Guardar cambios',
    search: 'Buscar',
    createPost: 'Crear publicación',
    copyClipboard: 'Copiado al portapapeles',
    edit: 'Editar',
    delete: 'Borrar',
    finish: 'Finalizar',
    paySubscription: 'Pagar subscripción',
  },
  nouns: {
    posts: 'Publicaciones',
    dashboard: 'Dashboard',
    products: 'Productos',
  },
  info: {
    createdBy: 'Creado por',
  },
  unknownUser: 'Usuario desconocido'
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperLight: {
    padding: '20px',
    marginTop: '20px',
  },
  paperDark: {
    padding: '20px',
    marginTop: '20px',
    backgroundColor: '#333333',
  },
  categoryContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  category: {
    padding: '5px',
    display: 'inline-block',
    borderRadius: '10px',
    marginRight: '5px',
  },
  categoryLabel: {
    color: 'white',
    fontWeight: 700,
    fontSize: '0.7rem',
  },
  darkText: {
    color: '#D9E1E7',
  },
  lightDate: {
    color: '#90A6B7',
  },
  darkDate: {
    color: '#D9E1E7',
  },
  moreContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  upperContainer: {
    display: 'flex',
  },
  lowerContainer: {
    display: 'flex',
    marginTop: '35px',
  },
  buttonContainer: {
    marginTop: 'auto',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  btn: {
    width: '177px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      width: '100%',
    },
  },
}));

export default useStyles;

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import useStyles from './AccountInfoStyle';
import CustomInput from '../CustomInput';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import { actions } from '../../../context/Reducer';
import validation from '../../../utils/validation';
import isValid from '../../../utils/isValid';
import constraints from './constraints';

const AccountInfo = ({ setOpen }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'account']);
  const { user } = API;
  const [state, dispatch] = useContext(Context);
  const [formData, setFormData] = useState({
    name: state.user.user.name,
    email: state.user.user.email,
  });
  const [errorsData, setErrorsData] = useState({
    name: null,
    email: null,
    password: null,
  });

  const updateInfo = async () => {
    try {
      await user.updateUser(state.user.user.id, formData, state.user.token);
      const tmpUser = { ...state.user, user: { ...state.user.user, name: formData.name, email: formData.email } };
      dispatch({ type: actions.SET_USER, payload: tmpUser });
      enqueueSnackbar(
        t('account:userInfo.success'),
        { variant: 'success' },
      );
      setOpen(false);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const handleChange = (e) => {
    const result = validation(e.target.name, e.target.value, constraints(t));

    setErrorsData({
      ...errorsData,
      [e.target.name]: result,
    });

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={classes.root}>
      <CustomInput
        name="name"
        value={formData.name}
        handleChange={handleChange}
        marginValues="0 0 0 0"
        label={t('account:userInfo.name')}
        errorMessage={errorsData.name}
      />
      <CustomInput
        name="email"
        value={formData.email}
        handleChange={handleChange}
        marginValues="35px 0 0 0"
        label="Email"
        errorMessage={errorsData.email}
      />
      <div className={classes.buttonContainer}>
        <Button
          style={{ color: '#4E6678' }}
          className={classes.btn}
          onClick={() => setOpen(false)}
        >
          {t('common:actions.cancel')}

        </Button>
        <Button
          disabled={!isValid(formData, constraints, t)}
          color="primary"
          variant="contained"
          className={classes.btn}
          onClick={updateInfo}
        >
          {t('common:actions.save')}

        </Button>
      </div>
    </div>
  );
};

export default AccountInfo;

AccountInfo.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

import React, { useContext, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Paper,
  IconButton,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DescriptionIcon from '@material-ui/icons/Description';
import PropTypes from 'prop-types';
import EventNoteIcon from '@material-ui/icons/EventNote';
import useStyles from './SideBarDrawerStyles';
import createWidgetScript from '../../../utils/createWidgetScript';
import { Context } from '../../../context/AppContextProvieder';

const SideBarDrawer = ({ closeMenu }) => {
  const classes = useStyles();
  const { lang, uri, comp } = useParams();
  const history = useHistory();
  const { t } = useTranslation(['sidebar']);
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const currentProduct = state.product;
  const logoUrl = currentProduct.attributes.logo_url;
  const productToken = currentProduct.attributes.public_token;

  const changeComponent = (component) => {
    history.push(`/${lang}/products/${state.product.attributes.product_uri}/${component}`);
    closeMenu();
  };

  const renderLogo = () => {
    if (logoUrl) {
      return <img className={classes.logo} src={logoUrl} alt="" />;
    }
    return (
      <Paper
        className={classes.paperImg}
        elevation={3}
      >
        <ImageIcon color="primary" />
      </Paper>
    );
  };

  useEffect(() => {
    createWidgetScript();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.navOptionsContainer}>
          <Typography
            className={classes.productName}
            align="center"
            variant="h5"
          >
            {currentProduct.attributes.name}
          </Typography>
          <IconButton
            size="small"
            className={classes.closeBtn}
            color="primary"
            onClick={closeMenu}
          >
            <CloseIcon />
          </IconButton>
          {renderLogo()}
          <List
            className={classes.listContainer}
          >
            <ListItem
              className={classes.option}
              onClick={() => history.push(`/${lang}/products`)}
            >
              <ListItemIcon>
                <AppsIcon
                  fontSize="small"
                  className={classes.notSelected}
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
              >
                <Typography
                  className={classes.notSelected}
                >
                  {t('products')}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              style={comp === 'dashboard' ? { backgroundColor: 'white', color: '#2892EE' } : null}
              className={classes.option}
              onClick={() => changeComponent('dashboard')}
            >
              <ListItemIcon>
                <TrendingUpIcon
                  fontSize="small"
                  className={comp === 'dashboard' ? classes.selected : classes.notSelected}
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
              >
                <Typography
                  className={comp === 'dashboard' ? classes.selected : classes.notSelected}
                >
                  {t('dashboard')}

                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              style={comp === 'posts' ? { backgroundColor: 'white', color: '#2892EE' } : null}
              className={classes.option}
              onClick={() => changeComponent('posts')}
            >
              <ListItemIcon>
                <DescriptionIcon
                  fontSize="small"
                  className={comp === 'posts' ? classes.selected : classes.notSelected}
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
              >
                <Typography
                  className={comp === 'posts' ? classes.selected : classes.notSelected}
                >
                  {t('posts')}

                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              style={comp === 'settings' ? { backgroundColor: 'white', color: '#2892EE' } : null}
              className={classes.option}
              onClick={() => changeComponent('settings')}
            >
              <ListItemIcon>
                <SettingsIcon
                  fontSize="small"
                  className={comp === 'settings' ? classes.selected : classes.notSelected}
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
              >
                <Typography
                  className={comp === 'settings' ? classes.selected : classes.notSelected}
                >
                  {t('settings')}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </div>
      </div>
      <div className={classes.btnsContainer}>
        <div className={classes.optionContainer}>
          <Link
            className={classes.link}
            target="_blank"
            to={`/${lang}/${uri}`}
          >
            <EventNoteIcon
              className={classes.optionIcon}
              fontSize="small"
            />
            <Typography className={classes.optionLabel} align="center">
              {t('sidebar:releaseNotes')}
            </Typography>
          </Link>
        </div>
        <div className={classes.optionContainer}>
          <div className={classes.widgetPreview} />
          <div
            id="my-widget-2"
            className="rp-container"
            lang={lang}
            data-param={productToken}
            data-preview="true"
          />
          <link rel="stylesheet" href="https://widget.releasepad.io/releasepad-bundle.min.css" />
          <Typography
            className={classes.optionLabel}
            style={{ marginTop: '5px' }}
            align="center"
          >
            {t('sidebar:preview')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SideBarDrawer;

SideBarDrawer.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};

import validation from './validation';

const isValid = (formData, constraints, t) => {
  let valid = true;
  Object.keys(formData).forEach((key) => {
    const result = validation(key, formData[key], constraints(t));
    if (result) {
      valid = false;
    }
  });
  return valid;
};

export default isValid;

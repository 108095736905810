const constraints = (t) => {
  return {
    name: {
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      }
    },
    website: {
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      },
    },
    description: {
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      }
    },
    uri: {
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      }
    },
  }
};

export default constraints;

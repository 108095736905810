import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CompactPicker } from 'react-color';
import { Paper, Typography } from '@material-ui/core';
import useStyles from './ColorBubbleStyle';

const Colorbubble = ({
  label, color, setColor, margin,
}) => {
  const classes = useStyles();
  const [colorPicker, setColorPicker] = useState(false);

  const changeColor = (newColor) => {
    setColor(newColor.hex);
  };

  return (
    <div>
      <Paper elevation={3} className={classes.paper} style={{ margin }}>
        <div
          onClick={() => setColorPicker(true)}
          className={classes.colorSquare}
          style={{ backgroundColor: color }}
        />
        <div>
          <Typography variant="h4">{label}</Typography>
          <Typography style={{ color: '#9A9A9A' }} variant="subtitle2">{color}</Typography>
        </div>

      </Paper>
      {colorPicker && (
        <>
          <div className={classes.cover} onClick={() => setColorPicker(false)} />
          <div className={classes.pickerContainer}>
            <CompactPicker
              disableAlpha
              color={color}
              onChange={changeColor}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Colorbubble;

Colorbubble.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
  margin: PropTypes.string.isRequired,
};

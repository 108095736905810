/* eslint-disable react/jsx-props-no-spreading */
// React
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// React router
import { Route, Redirect, useParams } from 'react-router-dom';

// Services
import { Context } from '../context/AppContextProvieder';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const { lang } = useParams();
  const language = navigator.language || navigator.userLanguage;
  const navLang = language.slice(0, 2);

  return (
    <Route
      {...rest}
      render={(props) => (
        state.user
          ? <Redirect to={`/${lang || navLang}/products`} />
          : <Component {...props} />
      )}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.element.isRequired,
  restricted: PropTypes.bool.isRequired,
};

export default PublicRoute;

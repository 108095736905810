import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  label: {
    color: '#5E7B91',
    fontWeight: 'bold',
  },
}));

export default useStyles;

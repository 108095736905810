export default {
  notFound: {
    partOne: 'Get started by clicking on ',
    partTwo: ', once it is published it will be shown here and you will be able to track your users likes and views.',
  },
  public: {
    noPosts: 'No posts found',
  },
  tabs: {
    published: 'Published posts',
    drafts: 'Drafts',
  },
  created: 'A new post has been created',
  deleted: 'Your post has been deleted',
  cardTitles: {
    createTitle: 'Create post',
    editTitle: 'Edit post',
    defaultPostBody: 'Type your new product feature here...',
    deleteTitle: 'Delete post',
  },
  messages: {
    confirmDelete: 'Are you sure you want to delete this post?',
  },
  new: {
    title: 'Create post',
    form: {
      title: 'Post title',
      category: 'Category',
      date: 'Publish date',
      description: 'Description',
    },
    draft: 'Mark as draft',
  },
  viewsDrawer: {
    date: 'Date',
    user: 'User',
    countryCode: 'Country code',
    noViews: "This post doesn't have views",
  },
  seenPost: 'Who has seen my post?',
};

const insertScript = () => {
  const script = document.createElement('script');
  script.src = 'https://widget.releasepad.io/releasepad-bundle.js';
  script.setAttribute('name', 'widget');
  script.async = true;
  document.body.appendChild(script);
};

const createWidgetScript = () => {
  const matches = document.querySelectorAll('script[name=widget]');

  if (matches.length === 0) {
    insertScript();
  } else {
    matches[0].remove();
    insertScript();
  }
};

export default createWidgetScript;

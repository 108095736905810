const constraints = (t) => {
  return {
    title: {
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      },
    },
    category: {
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      }
    },
    description: {
      presence: {
        message: t('common:validations:presence'),
        allowEmpty: false,
      }
    },
  }
};

export default constraints;

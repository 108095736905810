import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area,
} from 'recharts';
import useStyles from './DoubleAxisChartStyle';

const CustomizedLabelL = ({ value, color }) => (
  <text
    x={-100}
    y={30}
    width={180}
    transform="rotate(-90)"
    fill={color}
    fontSize={12.8}
  >
    {value}
  </text>
);

const CustomizedLabelR = ({ value, color, container }) => {
  const { containerWidth } = container.current.state;
  const yPos = containerWidth - 30;
  return (
    <text
      x={-90}
      y={yPos}
      width={180}
      transform="rotate(-90)"
      fill={color}
      fontSize={12.8}
    >
      {value}
    </text>
  );
};

const DoubleAxisChart = ({
  chartTitle, chartData, leftLabel, rightLabel,
}) => {
  const classes = useStyles();
  const container = useRef();
  return (
    <Paper elevation={3} className={classes.chartPaper}>
      <Typography
        className={classes.chartTitle}
        variant="h3"
      >
        {chartTitle}
      </Typography>
      <ResponsiveContainer
        ref={container}
        width="98%"
        height={180}
      >
        <AreaChart
          data={chartData}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
          fontSize={11}
        >
          <CartesianGrid vertical={false} strokeDasharray="1 0" />
          <XAxis dataKey="month" />

          <YAxis
            label={<CustomizedLabelL value={leftLabel} color="#8884d8" />}
            yAxisId="posts"
            axisLine={false}
          />
          <YAxis
            label={<CustomizedLabelR value={rightLabel} color="#82ca9d" container={container} />}
            yAxisId="views"
            axisLine={false}
            orientation="right"
          />

          <Tooltip />
          <Area yAxisId="posts" type="monotone" dataKey="posts" stroke="#8884d8" fill="rgb(136,132,216,0.6)" activeDot={{ r: 8 }} />
          <Area yAxisId="views" type="monotone" dataKey="views" stroke="#82ca9d" fill="rgb(130,202,157,0.6)" />
        </AreaChart>
      </ResponsiveContainer>

    </Paper>
  );
};

export default DoubleAxisChart;

DoubleAxisChart.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ).isRequired,
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
};

CustomizedLabelL.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

CustomizedLabelR.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

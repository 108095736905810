import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  body: {
    margin: '25px 0',
    // width: '50%',
    // minWidth: '500px',
  },
  codeBox: {
    border: '1px solid #D9E1E7',
    borderRadius: '10px',
    padding: '20px',
    overflow: 'hidden',
  },
  codeBody: {
    margin: '0',
    fontFamily: 'monospace',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  copyIcon: {
    float: 'right',
    color: '#4E6678',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;

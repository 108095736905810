import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useSnackbar } from 'notistack';
import useStyles from './CategoriesStyle';
import CreateCategory from '../CreateCategory';
import CategoryCard from '../CategoryCard';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import { CATEGORIES_PER_PAGE } from '../../../utils/constants';

const Categories = () => {
  const { t } = useTranslation(['categories', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();
  const { product } = API;
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const mapCategories = (categories) => {
    const array = categories.map((category) => ({
      id: category.id,
      name: category.attributes.name,
      color: category.attributes.background_color,
    }));

    setCategories(array);
  };

  const changePage = (e, page) => {
    setPage(page);
  };

  const fetchCategories = async () => {
    try {
      const { data } = await product.indexCategories(page, CATEGORIES_PER_PAGE, state.user.token);
      mapCategories(data.data);
      setTotalPages(data.meta.total_pages);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const newCategory = async (category) => {
    try {
      await product.createCategory({
        name: category.name,
        background_color: category.color,
      }, state.user.token);
      enqueueSnackbar(
        t('common:snackbarMessages.categories.created'),
        { variant: 'success' },
      );
      if (page === 1) {
        fetchCategories();
      } else {
        setPage(1);
      }
      setOpen(false);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 422) {
        enqueueSnackbar(
          t('common:snackbarMessages:formError'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const editCategory = (id) => {
    setEditCategoryId(id);
    setEdit(true);
    setOpen(true);
  };

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line
  }, [page]);

  const onClose = () => {
    setEdit(false);
    setEditCategoryId(null);
    setOpen(false);
  };

  return (
    <div>
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        open={open}
        onClose={onClose}
        anchor="right"
      >
        <CreateCategory
          edit={edit}
          categoryId={editCategoryId}
          createFunction={newCategory}
          fetchCategories={fetchCategories}
          onClose={onClose}
        />
      </Drawer>
      <div className={classes.titleContainer}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          {t('categories:create')}

        </Button>
      </div>
      <div className={classes.categoriesContainer}>
        {categories.map((category) => (
          <div
            key={category.id}
            className={classes.leftCard}
            onClick={() => editCategory(category.id)}
          >
            <CategoryCard
              title={category.name}
              color={category.color}
            />
          </div>
        ))}
      </div>
      <div className={classes.pagination}>
        <Pagination
          page={page}
          color="primary"
          count={totalPages}
          onChange={changePage}
        />
      </div>

    </div>
  );
};

export default Categories;

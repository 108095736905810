/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const headers = ({ text, level }) => {
  switch (level) {
    case 1:
      return (
        <>
          <h1>{text}</h1>
        </>
      );
    case 2:
      return (
        <>
          <h2>{text}</h2>
        </>
      );
    case 3:
      return (
        <>
          <h3>{text}</h3>
        </>
      );
    case 4:
      return (
        <>
          <h4>{text}</h4>
        </>
      );
    case 5:
      return (
        <>
          <h5>{text}</h5>
        </>
      );
    default:
      return (
        <>
          <h6>{text}</h6>
        </>
      );
  }
};

headers.propTypes = {
  text: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
};

const mapItems = ({ items }) => items.map((item) => <li>{item.replace(/&nbsp;/g, ' ')}</li>);

const mapTable = (data) => {
  const tableData = data.content.map((row) => {
    const items = row.map((item) => (
      <td style={{
        fontSize: '12.8px',
        padding: '10px',
        border: '1px solid #4E6678',
      }}
      >
        {item.replace(/&nbsp;/g, ' ')}
      </td>
    ));
    return <tr>{items}</tr>;
  });
  return tableData;
};
const blockToCode = ({ block: { type, data } }) => {
  const { items, style } = data;
  switch (type) {
    case 'header':
      return headers(data);
    case 'paragraph':
      return (
        <>
          <p
            style={{
              fontSize: '12.8px',
            }}
            dangerouslySetInnerHTML={{
              __html: data.text,
            }}
          />
        </>
      );
    case 'image':
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '35px 0',
          }}
        >
          <img
            style={{ maxWidth: '70%' }}
            src={data.file.url}
            alt={data.caption}
          />
          <br />
          {
            data.caption && (
              <em>
                {data.caption}
              </em>
            )
          }
        </div>
      );
    case 'list':
      if (style === 'ordered') {
        return (
          <>
            <ol>
              {mapItems({ items })}
            </ol>
          </>
        );
      }
      return (
        <>
          <ul>
            {mapItems({ items })}
          </ul>
        </>
      );

    case 'table':
      return (
        <table style={{
          borderCollapse: 'collapse',
          width: '700px',
          margin: '30px auto',
          tableLayout: 'fixed',
        }}
        >
          {mapTable(data)}
        </table>
      );
    // eslint-disable-next-line no-fallthrough
    default:
      return (
        <>
        </>
      );
  }
};

blockToCode.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default blockToCode;

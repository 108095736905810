import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';
import 'react-quill/dist/quill.snow.css';
import {
  Paper, Typography, Button, Checkbox, FormControl, InputLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import useStyles from './CreatePostStyle';
import CustomInput from '../CustomInput';
import CustomSelect from '../CustomSelect';
import Editor from '../Editor';
import isValid from '../../../utils/isValid';
import constraints from './constraints';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

const CreatePost = ({
  newPost, setNewPost, createPost, categories, cancelFunction, cardTitle, deleteFunction, user,
}) => {
  const accountId = user.user.account_id;
  const classes = useStyles();
  const editorInstance = React.useRef(null);
  const { t } = useTranslation(['posts', 'common']);
  const [open, setOpen] = useState(false);
  const initialValues = React.useRef({
    title: true,
    category: true,
  });


  const handleChange = async (event) => {
    const newValue = event.target.value;
    const field = event.target.name;

    const delta = await editorInstance.current.save();

    initialValues.current = {
      ...initialValues.current,
      [field]: false,
    };

    setNewPost({
      ...newPost,
      [field]: newValue,
      delta,
    });
  };

  const handleIsPrivate = async (event) => {
    const { checked } = event.target;
    const is_private = checked;
    const only_show_to_ids = is_private ? newPost.only_show_to_ids : '';
    const delta = await editorInstance.current.save();
    setNewPost({
      ...newPost,
      is_private,
      only_show_to_ids,
      delta,
    });
  };

  const handleCheck = async (event) => {
    const { checked } = event.target;
    const delta = await editorInstance.current.save();

    setNewPost({
      ...newPost,
      draft: checked,
      delta,
    });
  };

  const handleChangeDate = async (event) => {
    const date = event;
    const delta = await editorInstance.current.save();
    setNewPost({
      ...newPost,
      publish_at: date,
      delta,
    });
  };

  const savePost = async () => {
    const delta = await editorInstance.current.save();
    createPost(delta);
  };

  useEffect(() => {
    if (newPost.category === '' && categories.length > 0) {
      setNewPost((post) => ({
        ...post,
        category: categories[0].value,
      }));
    }
  }, []);

  return (
    <div className={classes.paperContainer}>
      <ConfirmModal
        open={open}
        onClose={() => setOpen(false)}
        acceptFunc={deleteFunction}
        title={t('posts:cardTitles.deleteTitle')}
        body={t('posts:messages.confirmDelete')}
      />
      <Paper className={classes.paper}>
        <div>
          <div className={classes.titleContainer}>
            <Typography variant="h2">{cardTitle}</Typography>
            <CloseIcon color="primary" className={classes.closeIcon} onClick={cancelFunction} />
          </div>
          <CustomInput
            name="title"
            label={t('posts:new.form.title')}
            value={newPost.title}
            handleChange={handleChange}
            marginValues="45px 0 0 0"
            errorMessage={initialValues.current.title ? '' : newPost.title === '' ? t('common:validations:presence') : ''}
          />
          <div className={classes.categoryContainer}>
            <CustomSelect
              name="category"
              label={t('posts:new.form.category')}
              value={newPost.category}
              handleChange={handleChange}
              marginValues="45px 0 0 0"
              errorMessage={initialValues.current.category ? '' : newPost.category === '' ? t('common:validations:presence') : ''}
              options={categories}
            />
            <span className={classes.separatorSpan} />
            <FormControl className={classes.datePicker} variant="outlined">
              <InputLabel shrink={false}>{t('posts:new.form.date')}</InputLabel>
              <DateTimePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                emptyLabel={t('posts:new.form.date')}
                value={newPost.publish_at}
                onChange={(e) => handleChangeDate(e)}
              />
            </FormControl>

          </div>
          <Typography className={classes.descriptionTitle}>{t('posts:new.form.description')}</Typography>
          <Paper elevation={3} className={classes.editorContainer}>
            <Editor
              data={newPost.delta}
              editorInstance={editorInstance}
            />
          </Paper>
        </div>
        { (accountId === 1 || accountId === 80) && (
          <div>
            <div className={classes.checkContainer}>
              <Checkbox
                checked={newPost.is_private || false}
                onChange={handleIsPrivate}
              />
              <Typography> Mark as private</Typography>
            </div>
            <p style={{ margin: '0 0 10px 10px', fontSize: '11px', color: 'gray' }}>
              By setting this post as private, it will only be shown
              to the user ids specified bellow when loading the widget
            </p>
            <div style={{ display: newPost.is_private ? 'block' : 'none' }}>
              <CustomInput
                name="only_show_to_ids"
                label="User Ids"
                value={newPost.only_show_to_ids || ''}
                handleChange={handleChange}
                marginValues="20px 0 0 0"
                errorMessage={(newPost.is_private && newPost.only_show_to_ids === '') ? t('common:validations:presence') : ''}
                multiline="true"
                rows={2}
              />
            </div>
          </div>
        )}
        <div className={classes.operationsContainer}>
          <div className={classes.checkContainer}>
            <Checkbox
              checked={newPost.draft}
              onChange={handleCheck}
            />
            <Typography>{t('posts:new.draft')}</Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              style={{ color: '#4E6678' }}
              onClick={cancelFunction}
            >
              {t('common:actions.cancel')}

            </Button>
            {deleteFunction && (
              <Button
                className={classes.deleteBtn}
                variant="outlined"
                onClick={() => setOpen(true)}
              >
                {t('common:actions.delete')}

              </Button>
            )}
            <Button
              disabled={!isValid(newPost, constraints, t)}
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={savePost}
            >
              {t('common:actions.save')}
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CreatePost;

CreatePost.propTypes = {
  newPost: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  setNewPost: PropTypes.func.isRequired,
  createPost: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ).isRequired,
  cancelFunction: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func,
  cardTitle: PropTypes.string.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};

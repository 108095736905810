import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '15px',
    marginBottom: '20px',
    // width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   padding: '30px',
    //   width: '500px',
    // },
    // [theme.breakpoints.up('md')]: {
    //   width: '900px',
    // },
  },
  category: {
    padding: '5px 10px',
    display: 'inline-block',
    borderRadius: '10px',
    marginBottom: '20px',
    minWidth: '65px',
  },
  categoryLabel: {
    color: 'white',
    fontWeight: 700,
    fontSize: '0.7rem',
  },
  body: {
    marginBottom: '20px',
    marginTop: '10px',
  },
  bottomContainer: {
    display: 'flex',
  },
  smallContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  icon: {
    marginRight: '10px',
    color: '#4E6678',
    fontSize: '1rem',
  },
  iconText: {
    color: '#4E6678',
    fontSize: '1rem',
    fontWeight: 700,
  },
  subtitle: {
    color: '#BABFC4',
    transform: 'translateY(5px)',
    marginLeft: '10px',
  },
}));

export default useStyles;

import './fonts.css';

const typography = {

  h1: {
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontWeight: 'bold',
    color: '#4E6678',
    fontSize: '1.5rem',
  },

  h2: {
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontWeight: 'bold',
    color: '#4E6678',
    fontSize: '1.4rem',
  },
  h3: {
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontWeight: 'bold',
    color: '#4E6678',
    fontSize: '1.2rem',
  },
  h4: {
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontWeight: 'bold',
    color: '#736A73',
    fontSize: '0.9rem',
  },

  h5: {
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontWeight: 'bold',
    color: '#5E7B91',
    fontSize: '0.8rem',
  },

  body1: {
    fontSize: '0.8rem',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
  },

  body2: {
    fontSize: '0.8rem',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    color: '#9A9A9A',
  },

  subtitle1: {
    fontSize: '0.7rem',
    fontFamily: 'Lato',
    color: '#4E6678',
  },

  subtitle2: {
    fontSize: '0.7rem',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
  },

  button: {
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontWeight: 'bold',
    textTransform: 'none',
  },
};

export default typography;

export default {
  recoverTitle: 'Recover password',
  forgotPassword: {
    emailSent: "We've sent a message with instructions to the specified address",
  },
  resetPassword: {
    newPassword: 'New password',
    confPassword: 'Confirm password',
    success: 'Your password has been updated',
  },
  actions: {
    recover: 'Recover',
    question: 'Already know your password?',
    answer: 'Log in',
  },
};

const numToMonth = (month, t) => {
  switch (month) {
    case 1:
      return t('months:january');
    case 2:
      return t('months:february');
    case 3:
      return t('months:march');
    case 4:
      return t('months:april');
    case 5:
      return t('months:may');
    case 6:
      return t('months:june');
    case 7:
      return t('months:july');
    case 8:
      return t('months:august');
    case 9:
      return t('months:september');
    case 10:
      return t('months:october');
    case 11:
      return t('months:november');
    case 12:
      return t('months:december');
    default:
      return t('months:january');
  }
};

export default numToMonth;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F7F8',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '35px 10px',
    },
  },
  container: {
    maxWidth: '900px',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  titleLogo: {
    maxWidth: '150px',
  },
  titleTypography: {
    color: '#000614',
    minWidth: '60px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  paper: {
    maxWidth: '700px',
    padding: '55px 35px',
    marginTop: '35px',
    [theme.breakpoints.down('sm')]: {
      padding: '55px 20px 20px 20px',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 25px',
    width: '100%'
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '25px',
  },
  actionsQuestion: {
    color: '#5E7B91',
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '256px'
    },
  },
  recaptchaText: {
    textAlign: 'center',
    color: '#999999',
    marginTop: '8px',
  },
}));

export default useStyles;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Switch } from '@material-ui/core';
// import HelpIcon from '@material-ui/icons/Help';
import useStyles from './BrandIdentityStyle';
import CustomInput from '../CustomInput';
import ColorBubble from '../ColorBubble';
import WidgetPreview from '../WidgetPreview';

const BrandIdentity = ({ widgetParams, setWidgetParams }) => {
  const classes = useStyles();
  const { t } = useTranslation(['brand', 'common']);
  const [open, setOpen] = useState(false);

  const handleChange = (value, name) => {
    setWidgetParams((oldParams) => ({
      ...oldParams,
      [name]: value,
    }));
  };

  return (
    <div>
      <WidgetPreview
        open={open}
        setOpen={setOpen}
      />
      <CustomInput
        label={t('brand:form.title')}
        name="title"
        marginValues="45px 0 0 0"
        value={widgetParams.title}
        handleChange={(e) => handleChange(e.target.value, 'title')}
      />
      <div className={classes.colorTitleContainer}>
        <Typography variant="h5">{t('brand:color.scheme')}</Typography>
        {/* <HelpIcon className={classes.helpIcon} /> */}
      </div>

      <div className={classes.bubblesContainer}>
        <ColorBubble
          // margin="0 10px 0 0"
          label={t('brand:color.primary')}
          color={widgetParams.color}
          setColor={(color) => handleChange(color, 'color')}
        />
        <div style={{ width: '100%' }} />
      </div>
      <div className={classes.switchContainer}>
        <Switch
          color="primary"
          checked={widgetParams.dark_mode}
          onChange={() => handleChange(!widgetParams.dark_mode, 'dark_mode')}
        />
        <Typography variant="h5">{t('brand:darkTheme')}</Typography>
      </div>
    </div>
  );
};

export default BrandIdentity;

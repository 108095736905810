import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  body: {
    margin: '0px auto',
    padding: '50px 0px',
    width: '70%',
    height: '87%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      padding: '50px 0',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      padding: '50px 0',
    },
  },
  drawerPaper: {
    // maxWidth: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  title: {
    color: '#4E6678',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '50px 50px 20px',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '55px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  createButton: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  productLogo: {
    width: '100%',
    marginTop: '150px',
  },
  productsContainer: {
    height: '100%',
    marginTop: '50px',
  },
  noProductsCont: {
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  noProductsImg: {
    width: 280,
    [theme.breakpoints.down('sm')]:{
      width: 220
    },
    [theme.breakpoints.down('xs')]:{
      width: 200
    }
  },
  notFoundText: {
    marginTop: '20px',
    color: '#161C22AF',
  },
  productsItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  productItem: {
    marginBottom: '3%',
    width: '31.33%',
    marginRight: '1%',
    marginLeft: '1%',
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginLeft: 0,
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    transform: 'translateX(2%)',
  },
  loaderContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '500px',
  },
}));

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EDF0F2',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: window.innerWidth * 0.4,
    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth,
    },
  },
  container: {
    width: '80%',
    paddingTop: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignSelf: 'center',
    },
  },
  closeBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  navOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
    width: '85%',
    borderRadius: '10px',
    padding: '0px',
    marginTop: '10px',
  },
  logo: {
    maxWidth: '85%',
    borderRadius: '10px',
    maxHeight: '100px',
    alignSelf: 'center',
  },
  productName: {
    marginTop: '25px',
    marginBottom: '10px',
  },
  listContainer: {
    marginTop: '20px',
    width: '100%',
  },
  selected: {
    color: '#2892EE',
    fontWeight: 700,
  },
  notSelected: {
    color: '#879FB1',
  },
  option: {
    paddingTop: '20px',
    paddingBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btnsContainer: {
    display: 'flex',
    padding: '10px 0',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'white',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '40%',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
  },
  optionIcon: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '18px',
    margin: '5px 0px',
  },
  optionLabel: {
    color: 'rgba(0,0,0,0.5)',
  },
  widgetPreview: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

export default useStyles;

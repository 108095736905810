export default {
  dataBubbles: {
    views: 'Vistas hasta la fecha',
    published: 'Publicaciones',
  },
  editButton: 'Editar producto',
  dashboard: 'Dashboard',
  mySites: 'Mis sitios',
  topPosts: {
    header: 'Publicaciones mas vistas',
    views: 'Vistas',
    title: 'Título',
    body: 'Descripción',
  },
  postNumber: 'Número de publicaciones y vistas',
  dates: {
    startDate: 'Fecha inicial',
    endDate: 'Fecha final',
  },
  chart: {
    leftLabel: 'Publicaciones',
    rightLabel: 'Vistas',
  },
};

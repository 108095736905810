import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  nameContainer: {
    display: 'flex',
    width: '100%',
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  btn: {
    width: '177px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      width: '100%',
    },
  },
}));

export default useStyles;
